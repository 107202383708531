import { Form, Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import FormDatepicker from '../FormItems/FormDatepicker.Component';
import FormInput from '../FormItems/FormInput.Component';
import FormSelect from '../FormItems/FormSelect.Component';

const propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  initialValue: PropTypes.object.isRequired,
  specialities: PropTypes.array.isRequired,
  leaders: PropTypes.array.isRequired,
};

/**
 *
 * @param {object} props
 * @param {string} props.title
 * @param {boolean} props.visible
 * @param {any} props.onOk
 * @param {any} props.onCancel
 * @param {any} props.getFieldDecorator
 * @param {object} props.initialValue
 * @param {Array} props.specialities
 * @param {Array} props.leaders
 *
 */
function EditGroupModal(props) {
  const {
    title,
    visible,
    onOk,
    onCancel,
    getFieldDecorator,
    initialValue,
    specialities,
    leaders,
  } = props;

  return (
    <Modal title={title} visible={visible} onOk={onOk} onCancel={onCancel}>
      <Form labelCol={{ span: 7 }} wrapperCol={{ span: 12 }}>
        <FormInput
          label="Nume"
          decoratorKey="name"
          getFieldDecorator={getFieldDecorator}
          message="Introdu codul grupei"
          initialValue={initialValue.name}
        />

        <FormDatepicker
          label="An"
          decoratorKey="year"
          getFieldDecorator={getFieldDecorator}
          initialValue={initialValue.year}
        />

        <FormSelect
          label="Specialitate"
          decoratorKey="specialityId"
          getFieldDecorator={getFieldDecorator}
          message="Selectează speciliatea"
          selectValues={specialities}
          initialValue={initialValue}
        />

        <FormSelect
          label="Șeful grupei"
          decoratorKey="leaderId"
          getFieldDecorator={getFieldDecorator}
          message="Selectează șeful grupei"
          selectValues={leaders}
          initialValue={initialValue}
        />
      </Form>
    </Modal>
  );
}

EditGroupModal.propTypes = propTypes;

export default EditGroupModal;
