import './index.css';
import 'antd/dist/antd.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import Navigation from './Middlewares/Navigation';
import { IndexReducer } from './Reducers/Index.Reducer';
import * as serviceWorker from './serviceWorker';

const store = createStore(IndexReducer);
const persist = persistStore(store);

const App = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persist}>
      <Navigation />
    </PersistGate>
  </Provider>
);
ReactDOM.render(App, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
