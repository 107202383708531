import './TemplateList.Styles.scss';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Icon, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DeleteModal from '../../Components/DeleteModal/DeleteModal.Component';
import { LoadingHandler } from '../../Reducers/Loading.Reducer';
import { AlertService } from '../../Services/Alert.Service';
import { TemplateService } from '../../Services/Template.Service';
import { ValidationService } from '../../Services/Validation.Service';

class TemplateListContainer extends Component {
  state = {
    templates: [],
    template: {},
    textFilter: null,
    limit: 10,
    offset: 0,
    total: 0,
    page: 1,
    deleteModal: false,
  };

  componentDidMount = () => {
    this.props.setLoading(true);
    this.init();
  };

  init = () => {
    this.getTemplates();
  };

  getTemplates = async () => {
    const { limit, offset, textFilter } = this.state;
    const { data, count } = await TemplateService.list({
      limit,
      offset,
      textFilter,
    });
    this.props.setLoading(false);

    this.setState({ templates: data, count });
  };

  onChangePage = (page) => {
    const { limit } = this.state;
    return this.setState(
      { page, offset: limit * page - limit },
      this.getTemplates
    );
  };

  onSearch = (textFilter) => {
    const isEmpty = ValidationService.isEmpty(textFilter);
    return this.setState(
      { textFilter: isEmpty ? null : textFilter },
      this.getTemplates
    );
  };

  navigateCreate = () => this.props.history.push('/template/edit');

  navigateEdit = (item) =>
    this.props.history.push({ pathname: '/template/edit', state: { item } });

  openDelete = (template) => this.setState({ deleteModal: true, template });

  closeDelete = () => this.setState({ deleteModal: false, template: {} });

  deleteTemplate = async (id) => {
    try {
      await TemplateService.delete(id);
      this.getTemplates();
      this.closeDelete();
    } catch (e) {
      AlertService.error(e);
    }
  };

  closeSearch = () => {
    this.setState({ textFilter: null }, this.getTemplates);
  };

  render() {
    const columns = [
      {
        title: 'Nume',
        dataIndex: 'title',
      },

      {
        title: 'Autor',
        render: (item) => `${item.account.firstName} ${item.account.lastName}`,
      },
      {
        title: 'Data creării',
        render: (item) => item.createdAt,
      },
      {
        title: 'Acțiuni',
        render: (item) => (
          <span>
            <Button onClick={() => this.navigateEdit(item)}>Editează</Button>
            <Divider type="vertical" />
            <Button onClick={() => this.openDelete(item)}>Șterge</Button>
            <Divider type="vertical" />
          </span>
        ),
      },
    ];
    const {
      templates,
      template,
      total,
      limit,
      textFilter,
      deleteModal,
    } = this.state;
    return (
      <div className="template-list__content template-list__content--padding">
        <div className="options">
          <div className="options__search">
            {textFilter !== null ? (
              <Button onClick={this.closeSearch}>
                <CloseCircleOutlined className="options__search__btn--cancel" />
              </Button>
            ) : null}
            <Search
              placeholder="Caută Șablon"
              onSearch={this.onSearch}
              className="options__search__input"
            />
          </div>
          <div className="options__container-icon">
            <div className="options__container-icon__icon">
              <Icon type="plus-circle" onClick={this.navigateCreate} />
            </div>
            <span className="options__container-icon__iconPopup-template">
              Adaugă Șablon
            </span>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={templates}
          className="table"
          pagination={{
            onChange: this.onChangePage,
            total: total,
            pageSize: limit,
          }}
          rowKey="id"
        />
        <DeleteModal
          item={template}
          visible={deleteModal}
          title="Șterge Student"
          text="Șterge?"
          onOk={this.deleteTemplate}
          onCancel={this.closeDelete}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(LoadingHandler.setLoading(loading)),
});

const WrappedTemplateList = Form.create({ name: 'TemplateListContainerForm' })(
  withRouter(TemplateListContainer)
);
export default connect(null, mapDispatchToProps)(WrappedTemplateList);
