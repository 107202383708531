import './User.Styles.scss';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Icon, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import CreateUserModal from '../../Components/CreateUserModal/CreateUserModal.Component';
import DeleteModal from '../../Components/DeleteModal/DeleteModal.Component';
import EditUserModal from '../../Components/EditUserModal/EditUserModal.Component';
import Typography from '../../Components/Typography/Typography';
import { ROLES_TRANSLATION } from '../../Constants/Roles.constant';
import { LoadingHandler } from '../../Reducers/Loading.Reducer';
import { AlertService } from '../../Services/Alert.Service';
import { UserService } from '../../Services/User.Service';
import { ValidationService } from '../../Services/Validation.Service';

class UserContainer extends Component {
  state = {
    //modal visibility
    editUserModal: false,
    createUserModal: false,
    deleteUserModal: false,

    //modal data
    editUserModalData: {},
    deleteUserData: null,

    //container data
    usersData: null,

    //pagination
    usersCount: null,
    currentPage: 1,
    limit: 7,
    offset: 0,
    textFilter: null,
  };

  componentDidMount = () => {
    this.props.setLoading(true);
    this.init();
  };

  init = () => {
    this.getUsers();
  };

  getUsers = async () => {
    const { limit, offset, textFilter } = this.state;
    try {
      const { data, count } = await UserService.list({
        limit,
        offset,
        textFilter,
      });
      this.props.setLoading(false);
      this.setState({ usersData: data, usersCount: count });
    } catch (e) {
      AlertService.error(e);
    }
  };

  createUser = async () => {
    const { usersData } = this.state;
    const { form } = this.props;
    const values = await ValidationService.validateForm(form);

    try {
      const newUser = await UserService.create(values);
      form.resetFields();
      this.setState(
        { usersData: [newUser, ...usersData] },
        this.closeCreateModal
      );
    } catch (e) {
      AlertService.error(e);
    }
  };

  deleteUser = async () => {
    const { deleteUserData } = this.state;
    try {
      await UserService.delete(deleteUserData.id);
      this.getUsers();
      this.closeDeleteUserModal();
    } catch (e) {
      AlertService.error(e);
    }
  };

  editUser = async () => {
    let { usersData, editUserModalData } = this.state;
    const { form } = this.props;
    const values = await ValidationService.validateForm(form);

    try {
      const index = usersData.findIndex(
        (item) => item.id === editUserModalData.id
      );
      form.resetFields();
      const editedUser = { ...editUserModalData, ...values };
      usersData[index] = editedUser;
      await UserService.edit(editedUser);
      this.setState({ usersData }, this.closeEdit);
    } catch (e) {
      AlertService.error(e);
    }
  };

  //pagination
  onChangePage = (page) => {
    const { limit } = this.state;
    return this.setState(
      { currentPage: page, offset: limit * page - limit },
      this.getUsers
    );
  };

  onSearch = (textFilter) => {
    const isEmpty = ValidationService.isEmpty(textFilter);
    return this.setState(
      { textFilter: isEmpty ? null : textFilter },
      this.getUsers
    );
  };

  closeSearch = () => {
    this.setState({ textFilter: null }, this.getUsers);
  };

  //modal functions

  openEdit = (data) => {
    this.setState({ editUserModal: true, editUserModalData: data });
  };

  closeEdit = () => this.setState({ editUserModal: false });

  openCreateModal = () => this.setState({ createUserModal: true });

  closeCreateModal = () => {
    this.props.form.resetFields();
    this.setState({ createUserModal: false });
  };

  openDeleteUserModal = (deleteUserData) =>
    this.setState({ deleteUserModal: true, deleteUserData });

  closeDeleteUserModal = () => this.setState({ deleteUserModal: false });

  render() {
    const {
      editUserModal,
      createUserModal,
      deleteUserModal,
      editUserModalData,
      usersData,
      usersCount,
      limit,
      textFilter,
      deleteUserData,
    } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const columns = [
      {
        title: 'Nume',
        dataIndex: 'firstName',
        key: 'firstName',
        render: (firstName) => <Typography text={firstName} />,
      },
      {
        title: 'Prenume',
        dataIndex: 'lastName',
        key: 'lastName',
        render: (lastName) => <Typography text={lastName} />,
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (email) => <Typography text={email} />,
      },
      {
        title: 'Rol',
        key: 'role',
        dataIndex: 'role',
        render: (role) => <Button>{ROLES_TRANSLATION[role.name]}</Button>,
      },
      {
        title: 'Grupa',
        key: 'group',
        render: (item) =>
          item.group === null ? (
            item.role.id === 1 ? (
              <Button>-</Button>
            ) : (
              <Button className="createGroup">
                <Link to="/groups">Creaza Grupa</Link>
              </Button>
            )
          ) : (
            <Button>{item.group.name}</Button>
          ),
      },
      {
        title: 'Funcție',
        dataIndex: 'position',
        key: 'position',
        render: (position) =>
          position ? <Typography text={position} /> : <Button>-</Button>,
      },
      {
        title: 'Acțiuni',
        key: 'action',
        render: (item) => (
          <span>
            <Button onClick={() => this.openEdit(item)}>Editează</Button>
            <Divider type="vertical" />
            <Button onClick={() => this.openDeleteUserModal(item)}>
              Șterge
            </Button>
          </span>
        ),
      },
    ];

    return (
      <div className="user__content user__content--padding">
        <div className="options">
          <div className="options__search">
            {textFilter !== null && (
              <Button onClick={this.closeSearch}>
                <CloseCircleOutlined className="options__search__btn--cancel" />
              </Button>
            )}
            <Search
              placeholder="Caută Utilizator"
              onSearch={this.onSearch}
              className="options__search__input"
            />
          </div>
          <div className="options__container-icon">
            <div className="options__container-icon__icon">
              <Icon type="plus-circle" onClick={this.openCreateModal} />
            </div>
            <span className="options__container-icon__iconPopup-user">
              Adaugă Utilizator
            </span>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={usersData}
          className="table"
          rowKey="id"
          pagination={{
            onChange: this.onChangePage,
            total: usersCount,
            pageSize: limit,
          }}
        />
        {editUserModal && (
          <EditUserModal
            title="Editează Utilizator"
            visible={editUserModal}
            onOk={this.editUser}
            onCancel={this.closeEdit}
            getFieldDecorator={getFieldDecorator}
            initialValue={editUserModalData}
          />
        )}

        {createUserModal && (
          <CreateUserModal
            title="Adaugă Utilizator"
            visible={createUserModal}
            onOk={this.createUser}
            onCancel={this.closeCreateModal}
            getFieldDecorator={getFieldDecorator}
          />
        )}

        <DeleteModal
          title="Șterge Utilizator"
          item={deleteUserData}
          visible={deleteUserModal}
          text="Sunteți sigur că doriți să ștergeți acest utilizator ?"
          onOk={this.deleteUser}
          onCancel={this.closeDeleteUserModal}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(LoadingHandler.setLoading(loading)),
});

const WrappedUserContainer = Form.create({ name: 'UserContainerForm' })(
  UserContainer
);

export default connect(null, mapDispatchToProps)(WrappedUserContainer);
