import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  setAuth: ['auth']
});

const ACTIONS = {
  setAuth: (state, { auth }) => ({ ...state, auth })
};

const INITIAL_STATE = Immutable({
  auth: ''
});

const HANDLERS = {
  [Types.SET_AUTH]: ACTIONS.setAuth
};

export const AuthReducer = createReducer(INITIAL_STATE, HANDLERS);

export const AuthTypes = Types;
export const AuthHandler = Creators;
