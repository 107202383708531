import './EditProfile.Styles.scss';

import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Form, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { AlertService } from '../../Services/Alert.Service';
import { UserService } from '../../Services/User.Service';
import FormInput from '../FormItems/FormInput.Component';

const propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  initialValue: PropTypes.object.isRequired,
  sendAvatarToContainer: PropTypes.func.isRequired,
};

/**
 *
 * @param {object} props
 * @param {object} props.initialValue
 * @param {boolean} props.visible
 * @param {any} props.onOk
 * @param {any} props.onCancel
 * @param {any} props.getFieldDecorator
 * @param {any} props.sendAvatarToContainer
 *
 */
class EditProfileModal extends Component {
  state = {
    uploadPhoto: null,
    avatar: null,
    key: 1,
    isChanged: false,
  };

  componentDidMount = () =>
    this.setState({ avatar: this.props.initialValue.avatar });

  updateAvatar = async (event) => {
    this.setState(
      { uploadPhoto: event.target.files[0], isChanged: true },
      this.uploadAvatar
    );
  };

  uploadAvatar = async () => {
    const { uploadPhoto } = this.state;
    const { name } = uploadPhoto;
    try {
      const fd = new FormData();
      fd.append('file', uploadPhoto, name);
      const avatar = await UserService.uploadAvatar(fd);
      this.setState(
        { avatar: avatar.image },
        this.props.sendAvatarToContainer(avatar)
      );
    } catch (e) {
      AlertService.error(e);
    }
  };

  render() {
    const { isChanged } = this.state;
    const {
      visible,
      onOk,
      onCancel,
      getFieldDecorator,
      initialValue,
    } = this.props;

    return (
      <Modal
        title="Editează Profil"
        visible={visible}
        onOk={onOk}
        okText="Editează"
        onCancel={onCancel}
        cancelText="Anulează"
      >
        <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }}>
          <Form.Item>
            <Avatar
              src={this.state.avatar}
              size={80}
              icon={<UserOutlined />}
              className="avatar-picture"
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: '98px',
              }}
              // className="avatarrContainer"
            >
              <input
                className="uploadInput"
                type="file"
                onChange={this.updateAvatar}
                ref={(fileInput) => (this.fileInput = fileInput)}
                accept="image/*"
                style={{ display: 'none' }}
              />
              <Button
                onClick={() => this.fileInput.click()}
                className="pickPhotoButton"
              >
                {isChanged ? 'Fotografia este aleasă' : 'Alege Fotografie'}
              </Button>
            </div>
          </Form.Item>

          <FormInput
            label="Prenume"
            getFieldDecorator={getFieldDecorator}
            initialValue={initialValue.firstName}
            decoratorKey="firstName"
            message="Introdu Prenumele"
          />

          <FormInput
            label="Nume"
            getFieldDecorator={getFieldDecorator}
            initialValue={initialValue.lastName}
            decoratorKey="lastName"
            message="Introdu Numele"
          />

          <FormInput
            label="Email"
            getFieldDecorator={getFieldDecorator}
            initialValue={initialValue.email}
            decoratorKey="email"
            message="Introdu e-mailul"
          />

          <FormInput
            label="Parolă"
            getFieldDecorator={getFieldDecorator}
            initialValue={initialValue.password}
            decoratorKey="password"
            message="Introdu parola"
            required={false}
          />

          <FormInput
            label="Funcție"
            getFieldDecorator={getFieldDecorator}
            initialValue={initialValue.position}
            decoratorKey="position"
            message="Introdu funcția"
            required={false}
          />
        </Form>
      </Modal>
    );
  }
}

EditProfileModal.propTypes = propTypes;

export default EditProfileModal;
