import './Statistics.Styles.scss';
import 'antd/dist/antd.css';

import { DownOutlined } from '@ant-design/icons';
import { Button, Col, Menu, Radio, Row, Statistic } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import LineChartHired from '../../Components/Charts/LineChart.Component';
import LineChartSatisfaction from '../../Components/Charts/LineChartSatisfaction.Component';
import PieChartHiredDomain from '../../Components/Charts/PieChartHiredDomain.Component';
import PieChartHiredRate from '../../Components/Charts/PieChartHiredRate.Component';
import PieChartSatisfaction from '../../Components/Charts/PieChartSatisfaction.Component';
import DropdownComponent from '../../Components/DropDown/Dropdown';
import { LoadingHandler } from '../../Reducers/Loading.Reducer';
import { AlertService } from '../../Services/Alert.Service';
import { GroupService } from '../../Services/Group.Service';
import { SpecialityService } from '../../Services/Speciality.Service';
import { StatisticsService } from '../../Services/Statistics.Service';

class StatisticsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // dropdown visibility
      yearDropdown: false,
      groupDropdown: false,
      specialityDropdown: false,
      // dropdown data
      allGroups: null,
      allYears: null,
      allSpecialities: null,
      //Statistics data
      statisticsData: [],

      //lilian sa-mi dea endpoint cu toate grupele/specialitatile
      limit: 5,
      offset: 0,
      textFilter: null,

      loading: true,
    };
    this.chartReference = React.createRef();
  }

  componentDidMount = async () => {
    const { setLoading } = this.props;
    setLoading(true);
    this.getGroups();
    this.getSpecialities();
    this.getYears();
    this.getGlobalStats();
  };

  getGroups = async () => {
    try {
      const { data } = await GroupService.listAllGroups();
      this.setState({
        allGroups: data,
      });
    } catch (e) {
      AlertService.error(e);
    }
  };

  getYears = async () => {
    try {
      const data = await StatisticsService.getYears();
      return this.setState({ allYears: data });
    } catch (e) {
      AlertService.error(e);
    }
  };

  getGlobalStats = async () => {
    const { setLoading } = this.props;

    try {
      const { data } = await StatisticsService.getGlobalStatistics();
      setLoading(false);
      this.setState({
        statisticsData: data,
        loading: false,
      });
    } catch (e) {
      AlertService.error(e);
    }
  };

  getSpecialities = async () => {
    try {
      const { data } = await SpecialityService.list({
        textFilter: null,
      });
      return this.setState({
        allSpecialities: data,
      });
    } catch (e) {
      AlertService.error(e);
    }
  };

  getSelectedGroup = async (groupId) => {
    try {
      this.setState({ statisticsData: [] }); //empty statistics data from previous display
      const data = await StatisticsService.getGroupStatistics({ groupId });
      this.setState({
        statisticsData: [...this.state.statisticsData, data],
      });
    } catch (e) {
      AlertService.error(e);
    }
  };

  getSelectedYear = async (year) => {
    try {
      this.setState({ statisticsData: [] });
      const data = await StatisticsService.getYear({ year });
      this.setState({
        statisticsData: [...this.state.statisticsData, data],
      });
    } catch (e) {
      AlertService.error(e);
    }
  };

  getSelectedSpeciality = async (specialityId) => {
    try {
      const { data } = await StatisticsService.getGlobalStatistics({
        specialityId,
      });

      this.setState({ statisticsData: data, loading: false });
    } catch (e) {
      AlertService.error(e);
    }
  };

  showDropdown = (filter) => {
    if (filter === 'yearDropdown') {
      return this.setState({
        yearDropdown: true,
        groupDropdown: false,
        specialityDropdown: false,
      });
    }
    if (filter === 'groupDropdown') {
      return this.setState({
        yearDropdown: false,
        groupDropdown: true,
        specialityDropdown: false,
      });
    }
    return this.setState({
      yearDropdown: false,
      groupDropdown: false,
      specialityDropdown: true,
    });
  };

  getRaport = async (year) => {
    try {
      if (
        this.state.statisticsData[0].name === '' &&
        this.state.statisticsData[0].speciality === null
      ) {
        const data = await StatisticsService.getRaport(year);
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Raport ${year} CEITI.csv`);
        document.body.appendChild(link);
        link.click();
      } else {
        AlertService.error('Nu ai ales un an');
      }
    } catch (error) {
      AlertService.error(error);
    }
  };

  render() {
    const {
      groupDropdown,
      yearDropdown,
      specialityDropdown,
      allGroups,
      allSpecialities,
      allYears,
      statisticsData,
      loading,
    } = this.state;

    return (
      <div className="statistics-page">
        <div className="select">
          <div>
            <p>Filtrează după:</p>
            <Radio.Group>
              <Radio.Button
                onClick={() => {
                  this.showDropdown('yearDropdown');
                }}
                value="yearDropdown"
              >
                An
              </Radio.Button>
              <Radio.Button
                onClick={() => {
                  this.showDropdown('groupDropdown');
                }}
                value="groupDropdown"
              >
                Grupă
              </Radio.Button>
              <Radio.Button
                onClick={() => {
                  this.showDropdown('specialityDropdown');
                }}
                value="specialityDropdown"
              >
                Specialitate
              </Radio.Button>
            </Radio.Group>

            <div className="select__dropdown">
              {groupDropdown && (
                <DropdownComponent
                  element={
                    <Button>
                      Grupa
                      <DownOutlined />
                    </Button>
                  }
                >
                  <Menu>
                    {!loading &&
                      allGroups.map((group, index) => (
                        <Menu.Item
                          key={index}
                          onClick={() => {
                            this.getSelectedGroup(group.id);
                          }}
                        >
                          {group.name}
                        </Menu.Item>
                      ))}
                  </Menu>
                </DropdownComponent>
              )}
              {yearDropdown && (
                <DropdownComponent
                  element={
                    <Button>
                      Anul
                      <DownOutlined />
                    </Button>
                  }
                >
                  <Menu>
                    {!loading &&
                      allYears.map((years, index) => (
                        <Menu.Item
                          key={index}
                          onClick={() => {
                            this.getSelectedYear(years.year);
                          }}
                        >
                          {years.year}
                        </Menu.Item>
                      ))}
                  </Menu>
                </DropdownComponent>
              )}
              {specialityDropdown && (
                <DropdownComponent
                  element={
                    <Button>
                      Specialitatea
                      <DownOutlined />
                    </Button>
                  }
                >
                  <Menu>
                    {!loading &&
                      allSpecialities.map((speciality, index) => (
                        <Menu.Item
                          key={index}
                          onClick={() => {
                            this.getSelectedSpeciality(speciality.id);
                          }}
                        >
                          {speciality.name}
                        </Menu.Item>
                      ))}
                  </Menu>
                </DropdownComponent>
              )}
            </div>
          </div>
          {yearDropdown && (
            <Button
              type="primary"
              onClick={() => this.getRaport(statisticsData[0].year)}
            >
              Descarcă Raport
            </Button>
          )}
        </div>

        <div className="statistics">
          {!groupDropdown && !yearDropdown && (
            <div className="statistics__charts">
              <div className="statistics__charts--satisfaction-chart">
                <LineChartSatisfaction statisticsData={statisticsData} />
              </div>
              <div className="statistics__charts--hired-chart">
                <LineChartHired statisticsData={statisticsData} />
              </div>
            </div>
          )}

          <div className="statistics__content">
            {!loading &&
              statisticsData.map((statisticsData, key) => (
                <div className="data-table" key={key}>
                  <div className="data-table__header">
                    {statisticsData.name && <p>Grupa: {statisticsData.name}</p>}

                    <p>An Absolvire: {statisticsData.year}</p>
                    {statisticsData.speciality && (
                      <p>Specialitatea: {statisticsData.speciality}</p>
                    )}
                  </div>
                  <div className="data-table__row">
                    <Row>
                      <div className="data-table__row__cell">
                        <Col span={20}>
                          <Statistic
                            title="Numarul de Absolventi"
                            value={statisticsData.groupCount}
                          />
                        </Col>
                      </div>
                    </Row>
                    <Row>
                      <Col span={20}>
                        <div className="data-table__row__cell">
                          <Statistic
                            title="Angajati in domeniu"
                            value={statisticsData.hiredInDomain}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={20}>
                        <div className="data-table__row__cell">
                          <Statistic
                            title="Angajati in alt domeniu"
                            value={statisticsData.hiredAnotherDomain}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={20}>
                        <div className="data-table__row__cell">
                          <Statistic
                            title="Continuă studiile"
                            value={statisticsData.continueStudy}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={20}>
                        <div className="data-table__row__cell">
                          <Statistic
                            title="Neangajat"
                            value={statisticsData.unemployed}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="data-table__row">
                    <Row>
                      <Col span={20}>
                        <div className="data-table__row__cell">
                          <Statistic
                            title="Afacere proprie"
                            value={statisticsData.antreprenor}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={20}>
                        <div className="data-table__row__cell">
                          <Statistic
                            title="Serviciul militar"
                            value={statisticsData.militaryService}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={20}>
                        <div className="data-table__row__cell">
                          <Statistic
                            title="Ingrijire Copil"
                            value={statisticsData.childCare}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={20}>
                        <div className="data-table__row__cell">
                          <Statistic
                            title="Plecat Peste hotare"
                            value={statisticsData.goneAbroad}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={20}>
                        <div className="data-table__row__cell">
                          <Statistic
                            title="Alte"
                            value={statisticsData.other}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="data-table__row">
                    <div className="data-table__row__pie-chart">
                      <p className="data-table__row__pie-chart__title">
                        Nivelul de Feedback
                      </p>
                      <PieChartSatisfaction statisticsData={statisticsData} />
                      <div className="data-table__row__pie-chart--content">
                        <div>
                          <div className="data-table__row__pie-chart__legend">
                            <p className="data-table__row__pie-chart__legend__title">
                              Foarte Bun
                            </p>
                            <div className="data-table__row__pie-chart__legend--blue"></div>
                          </div>
                          <div className="data-table__row__pie-chart__legend">
                            <p className="data-table__row__pie-chart__legend__title">
                              Bun
                            </p>
                            <div className="data-table__row__pie-chart__legend--green"></div>
                          </div>
                        </div>
                        <div style={{ marginLeft: '30px' }}>
                          <div className="data-table__row__pie-chart__legend">
                            <p className="data-table__row__pie-chart__legend__title">
                              Satisfacator
                            </p>
                            <div className="data-table__row__pie-chart__legend--yellow"></div>
                          </div>
                          <div className="data-table__row__pie-chart__legend">
                            <p className="data-table__row__pie-chart__legend__title">
                              Nesatisfacator
                            </p>
                            <div className="data-table__row__pie-chart__legend--red"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="data-table__row__pie-chart">
                      <p className="data-table__row__pie-chart__title">
                        Rata Angajat/Neangajat
                      </p>
                      <PieChartHiredRate statisticsData={statisticsData} />
                      <div className="data-table__row__pie-chart--content">
                        <div>
                          <div className="data-table__row__pie-chart__legend">
                            <p className="data-table__row__pie-chart__legend__title">
                              Angajat
                            </p>
                            <div className="data-table__row__pie-chart__legend--blue"></div>
                          </div>
                          <div className="data-table__row__pie-chart__legend">
                            <p className="data-table__row__pie-chart__legend__title">
                              Neangajat
                            </p>
                            <div className="data-table__row__pie-chart__legend--red"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="data-table__row__pie-chart">
                      <p className="data-table__row__pie-chart__title">
                        Rata angajat in IT/alt domeniu
                      </p>
                      <PieChartHiredDomain statisticsData={statisticsData} />
                      <div className="data-table__row__pie-chart--content">
                        <div>
                          <div className="data-table__row__pie-chart__legend">
                            <p className="data-table__row__pie-chart__legend__title">
                              Angajat in domeniu IT
                            </p>
                            <div className="data-table__row__pie-chart__legend--blue"></div>
                          </div>
                          <div className="data-table__row__pie-chart__legend">
                            <p className="data-table__row__pie-chart__legend__title">
                              Angajat in alt domeniu
                            </p>
                            <div className="data-table__row__pie-chart__legend--red"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="data-table__divider"></div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
});

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(LoadingHandler.setLoading(loading)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticsContainer);
