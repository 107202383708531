import { Form, Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import FormInput from '../FormItems/FormInput.Component';

const propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  initialValue: PropTypes.object.isRequired,
};

/**
 *
 * @param {object} props
 * @param {string} props.title
 * @param {boolean} props.visible
 * @param {any} props.onOk
 * @param {any} props.onCancel
 * @param {any} props.getFieldDecorator
 * @param {object} props.initialValue
 *
 */
function EditStudentModal(props) {
  const {
    title,
    visible,
    onOk,
    onCancel,
    getFieldDecorator,
    initialValue,
  } = props;

  return (
    <Modal title={title} visible={visible} onOk={onOk} onCancel={onCancel}>
      <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }}>
        <FormInput
          label="Prenume"
          getFieldDecorator={getFieldDecorator}
          decoratorKey="firstName"
          initialValue={initialValue.firstName}
          message="Introdu Prenumele"
        />

        <FormInput
          label="Nume"
          getFieldDecorator={getFieldDecorator}
          decoratorKey="lastName"
          initialValue={initialValue.lastName}
          message="Introdu Prenumele"
        />

        <FormInput
          label="Email"
          getFieldDecorator={getFieldDecorator}
          decoratorKey="email"
          initialValue={initialValue.email}
          message="Introdu email-ul"
        />

        <FormInput
          label="Telefon"
          getFieldDecorator={getFieldDecorator}
          decoratorKey="phone"
          initialValue={initialValue.phone}
          message="Introdu telefonul"
        />
      </Form>
    </Modal>
  );
}

EditStudentModal.propTypes = propTypes;

export default EditStudentModal;
