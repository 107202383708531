import { DatePicker, Form } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  label: PropTypes.string.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  decoratorKey: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  initialValue: PropTypes.number,
};

/**
 *
 * @param {object} props
 * @param {string} props.label
 * @param {any} props.getFieldDecorator
 * @param {string} props.decoratorKey
 * @param {boolean} [props.required]
 * @param {string} [props.initialValue]
 *
 */

function FormDatepicker(props) {
  const {
    label,
    getFieldDecorator,
    decoratorKey,
    required,
    initialValue,
  } = props;
  return (
    <Form.Item label={label}>
      {getFieldDecorator(decoratorKey, {
        initialValue: initialValue && moment(initialValue, 'YYYY'),
        rules: [{ required: required }],
      })(<DatePicker mode="year" format="YYYY" />)}
    </Form.Item>
  );
}
FormDatepicker.defaultProps = {
  initialValue: null,
  required: true,
};

FormDatepicker.propTypes = propTypes;

export default FormDatepicker;
