import './Groups.Styles.scss';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Icon, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CreateGroupModal from '../../Components/CreateGroupModal/CreateGroupModal.Component';
import DeleteModal from '../../Components/DeleteModal/DeleteModal.Component';
import EditGroupModal from '../../Components/EditGroupModal/EditGroupModal.Component';
import PickTemplateModal from '../../Components/PickTemplateModal/PickTemplate.Component';
import { LoadingHandler } from '../../Reducers/Loading.Reducer';
import { AlertService } from '../../Services/Alert.Service';
import { GroupService } from '../../Services/Group.Service';
import { SpecialityService } from '../../Services/Speciality.Service';
import { UserService } from '../../Services/User.Service';
import { ValidationService } from '../../Services/Validation.Service';

class GroupsContainer extends Component {
  state = {
    // modals
    editModal: false,
    createModal: false,
    deleteModal: false,
    pickTemplateModal: false,
    // arrays
    leaders: [],
    specialities: [],
    groups: [],
    // pagination
    textFilter: null,
    groupsCount: 0,
    limit: 7,
    offset: 0,
    currentPage: 1,
    // modals data
    editGroupData: {},
    deleteGroupData: {},

    //others
    groupId: null,
    loading: true,
  };

  componentDidMount = () => {
    this.init();
  };

  init = () => {
    this.props.setLoading(true);
    this.getGroups();
    this.getSpecialities();
  };

  // GET REQUESTS

  getGroups = async () => {
    const { limit, offset, textFilter } = this.state;

    try {
      const user = await UserService.me();
      const iAmLeader = user.groupId ? true : false;

      const { data, count } = await GroupService.list({
        limit,
        offset,
        textFilter,
      });

      if (iAmLeader) {
        let filteredGroups = [];

        data.forEach((group, i) => {
          if (group.id === user.groupId) {
            filteredGroups.push(group);
          }
        });

        this.setState({
          groups: filteredGroups,
          groupsCount: count,
          loading: false,
        });
        this.props.setLoading(false);
      } else {
        this.props.setLoading(false);
        this.setState({ groups: data, groupsCount: count, loading: false });
      }
    } catch (e) {
      AlertService.error(e);
    }
  };

  getLeaders = async (editGroupData) => {
    try {
      const leaders = await UserService.availableLeaders();
      if (editGroupData === null) {
        this.setState({ leaders });
        return;
      }
      const addCurrentLeader = [...leaders];
      if (editGroupData.leader) {
        addCurrentLeader.push(editGroupData.leader);
      }
      this.setState({ leaders: addCurrentLeader });
    } catch (e) {
      AlertService.error(e);
    }
  };

  getSpecialities = async () => {
    const { data } = await SpecialityService.list({ textFilter: null });
    return this.setState({ specialities: data });
  };

  // GROUP CRUD

  createGroup = async () => {
    const { groups } = this.state;
    const { form } = this.props;
    const values = await ValidationService.validateForm(form);
    values.year = values.year.year();
    try {
      const newGroup = await GroupService.create(values);
      form.resetFields();
      this.setState({ groups: [newGroup, ...groups] }, this.closeCreate);
    } catch (e) {
      AlertService.error(e);
    }
  };

  editGroup = async () => {
    let { groups, editGroupData } = this.state;
    const { form } = this.props;
    const values = await ValidationService.validateForm(form);
    values.year = values.year.year();
    try {
      const index = groups.findIndex((item) => item.id === editGroupData.id);
      const editedGroup = { ...editGroupData, ...values };
      const newData = await GroupService.edit(editedGroup);
      form.resetFields();
      groups[index] = newData;
      this.setState({ groups }, this.closeEdit);
    } catch (e) {
      AlertService.error(e);
    }
  };

  deleteGroup = async () => {
    let { deleteGroupData } = this.state;
    try {
      await GroupService.delete(deleteGroupData.id);
      this.getGroups();
      this.closeDelete();
    } catch (e) {
      AlertService.error(e);
    }
  };

  // GROUP PAGINATION

  onChangePage = (page) => {
    const { limit } = this.state;
    return this.setState(
      { currentPage: page, offset: limit * page - limit },
      this.getGroups
    );
  };

  onSearch = (textFilter) => {
    const isEmpty = ValidationService.isEmpty(textFilter);
    return this.setState(
      { textFilter: isEmpty ? null : textFilter },
      this.getGroups
    );
  };

  closeSearch = () => {
    this.setState({ textFilter: null }, this.getGroups);
  };

  // MODALS

  openEdit = (editGroupData) => {
    this.getLeaders(editGroupData);
    this.setState({
      editModal: true,
      editGroupData,
    });
  };

  closeEdit = () => {
    let { leaders } = this.state;

    leaders.pop();
    return this.setState({ editModal: false, leaders });
  };

  openCreate = () => {
    this.getLeaders(null);
    this.setState({ createModal: true });
  };

  closeCreate = () => {
    const { form } = this.props;
    form.resetFields();
    this.setState({ createModal: false }, () => this.getLeaders(null));
  };

  openDelete = (deleteGroupData) =>
    this.setState({ deleteModal: true, deleteGroupData });

  closeDelete = () => this.setState({ deleteModal: false });

  openTemplatePicker = (groupData) =>
    this.setState({
      pickTemplateModal: true,
      groupId: groupData.id,
    });

  closeTemplatePicker = () =>
    this.setState({
      pickTemplateModal: false,
      groupId: null,
    });

  // OTHER

  pushIdToStudents = (groupId) => {
    this.props.history.push({ pathname: '/students', state: { groupId } });
  };

  render() {
    const {
      editModal,
      createModal,
      groups,
      deleteModal,
      groupsCount,
      limit,
      editGroupData,
      specialities,
      leaders,
      pickTemplateModal,
      textFilter,
      groupId,
      deleteGroupData,
    } = this.state;

    const { form } = this.props;
    const { getFieldDecorator } = form;
    const columns = [
      {
        title: 'Nume',
        key: 'item.id',
        render: (item) => (
          <Button onClick={() => this.pushIdToStudents(item.id)}>
            {item.name}
          </Button>
        ),
      },
      {
        title: 'An',
        dataIndex: 'year',
      },
      {
        title: 'Specialitate',
        dataIndex: 'speciality.name',
        render: (item) => <span>{item ? item : 'nu este specialitate'}</span>,
      },
      {
        title: 'Șef Grupă',
        render: ({ leader }) => (
          <span>
            {leader ? (
              <div>
                {leader.firstName} {leader.lastName}
              </div>
            ) : (
              ' - '
            )}
          </span>
        ),
      },
      {
        title: 'Acțiuni',
        render: (item) => (
          <span>
            <Button onClick={() => this.openEdit(item)}>Editează</Button>
            <Divider type="vertical" />
            <Button onClick={() => this.openDelete(item)}>Șterge</Button>
            <Divider type="vertical" />
            <Button onClick={() => this.openTemplatePicker(item)}>
              Trimite Forma
            </Button>
          </span>
        ),
      },
    ];
    return (
      <div className="groups__content groups__content--padding">
        <div className="options">
          <div className="options__search">
            {textFilter && (
              <Button onClick={this.closeSearch}>
                <CloseCircleOutlined className="options__search__btn--cancel" />
              </Button>
            )}
            <Search
              placeholder="Caută Grupa"
              onSearch={this.onSearch}
              className="options__search__input"
            />
          </div>
          <div className="options__container-icon">
            <div className="options__container-icon__icon">
              <Icon type="plus-circle" onClick={this.openCreate} />
            </div>
            <span className="options__container-icon__iconPopup">
              Adaugă Grupă
            </span>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={groups}
          className="table"
          pagination={{
            onChange: this.onChangePage,
            total: groupsCount,
            pageSize: limit,
          }}
          rowKey="id"
        />
        <EditGroupModal
          title="Editează Grupă"
          visible={editModal}
          onOk={this.editGroup}
          onCancel={this.closeEdit}
          getFieldDecorator={getFieldDecorator}
          initialValue={editGroupData}
          specialities={specialities}
          leaders={leaders}
        />

        <CreateGroupModal
          title="Adaugă Grupă"
          visible={createModal}
          onOk={this.createGroup}
          onCancel={this.closeCreate}
          getFieldDecorator={getFieldDecorator}
          specialities={specialities}
          leaders={leaders}
        />

        <DeleteModal
          title="Șterge Grupă"
          item={deleteGroupData}
          visible={deleteModal}
          text="Șterge grupa?"
          onOk={this.deleteGroup}
          onCancel={this.closeDelete}
        />

        {pickTemplateModal && (
          <PickTemplateModal
            visible={pickTemplateModal}
            groupId={groupId}
            onCancel={this.closeTemplatePicker}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(LoadingHandler.setLoading(loading)),
});

const WrappedGroupsContainer = Form.create({ name: 'GroupsContainerForm' })(
  withRouter(GroupsContainer)
);

export default connect(null, mapDispatchToProps)(WrappedGroupsContainer);
