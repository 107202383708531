import { Dropdown } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  element: PropTypes.any,
};

/**
 *
 * @param {object} props
 * @param {any} props.children
 * @param {any} props.element
 *
 */
function DropdownComponent(props) {
  const { children, element } = props;
  return <Dropdown overlay={children}>{element}</Dropdown>;
}

DropdownComponent.propTypes = propTypes;

export default DropdownComponent;
