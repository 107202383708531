import './Students.Styles.scss';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Icon, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CreateStudentModal from '../../Components/CreateStudentModal/CreateStudentModal.Component';
import DeleteModal from '../../Components/DeleteModal/DeleteModal.Component';
import EditStudentModal from '../../Components/EditStudentModal/EditStudentModal.Component';
import PickTemplateModal from '../../Components/PickTemplateModal/PickTemplate.Component';
import { LoadingHandler } from '../../Reducers/Loading.Reducer';
import { AlertService } from '../../Services/Alert.Service';
import { StudentsService } from '../../Services/Students.Service';
import { ValidationService } from '../../Services/Validation.Service';

class StudentsContainer extends Component {
  state = {
    //modal visibility
    editStudentsModal: false,
    createStudentsModal: false,
    deleteStudentsModal: false,
    pickTemplateModal: false,

    //modals data
    editStudentData: {},
    deleteStudentData: {},

    //container data
    students: [],

    //pagination
    limit: 5,
    offset: 0,
    textFiler: null,
    currentPage: 1,
    studentCount: 0,

    //data for Form Sening
    groupId: null,
    studentId: null,
  };
  componentDidMount = () => {
    const { groupId } = this.props.location.state;

    this.props.setLoading(true);
    return this.setState({ groupId }, this.init);
  };

  init = () => {
    this.getStudents();
  };

  getStudents = async () => {
    const { limit, textFilter, offset, groupId } = this.state;
    const { data, count } = await StudentsService.list({
      limit,
      offset,
      textFilter,
      groupId,
    });
    this.props.setLoading(false);
    this.setState({ students: data, studentsCount: count });
  };

  createStudent = async () => {
    const { students } = this.state;
    const { form } = this.props;
    let values = await ValidationService.validateForm(form);

    try {
      values = { ...values, groupId: this.state.groupId };
      const newStudent = await StudentsService.create(values);
      form.resetFields();
      this.setState(
        { students: [...students, newStudent] },
        this.closeCreateModal
      );
    } catch (e) {
      AlertService.error(e);
    }
  };
  editStudent = async () => {
    let { students, editStudentData } = this.state;
    const { form } = this.props;
    const values = await ValidationService.validateForm(form);

    try {
      const index = students.findIndex(
        (item) => item.id === editStudentData.id
      );
      const editedStudent = { ...editStudentData, ...values };
      students[index] = editedStudent;
      form.resetFields();
      this.setState({ students }, this.closeEdit);
      await StudentsService.edit(editedStudent);
    } catch (e) {
      AlertService.error(e);
    }
  };
  deleteStudent = async () => {
    let { students, deleteStudentData } = this.state;
    try {
      await StudentsService.delete(deleteStudentData.id);
      const filteredStudents = students.filter(
        (item) => item.id !== deleteStudentData.id
      );
      this.setState(
        { students: filteredStudents },
        this.closeDeleteStudentsModal
      );
    } catch (e) {
      AlertService.error(e);
    }
  };

  onChangePage = (page) => {
    const { limit } = this.state;
    return this.setState(
      { currentPage: page, offset: limit * page - limit },
      this.getStudents
    );
  };

  onSearch = (textFilter) => {
    const isEmpty = ValidationService.isEmpty(textFilter);
    return this.setState(
      { textFilter: isEmpty ? null : textFilter },
      this.getStudents
    );
  };

  closeSearch = () => {
    this.setState({ textFilter: null }, this.getStudents);
  };

  //modal functions
  openEdit = (editStudentData) =>
    this.setState({ editStudentsModal: true, editStudentData });

  closeEdit = () => this.setState({ editStudentsModal: false });

  openCreateModal = () => this.setState({ createStudentsModal: true });

  closeCreateModal = () => {
    this.props.form.resetFields();
    this.setState({ createStudentsModal: false, deleteStudentData: {} });
  };
  openDeleteStudentsModal = (deleteStudentData) =>
    this.setState({ deleteStudentsModal: true, deleteStudentData });

  closeDeleteStudentsModal = () =>
    this.setState({ deleteStudentsModal: false });

  openTemplatePicker = (studentData) => {
    this.setState({
      pickTemplateModal: true,
      studentId: studentData.id,
    });
  };

  closeTemplatePicker = () => {
    this.setState({
      pickTemplateModal: false,
    });
  };

  render() {
    const {
      editStudentsModal,
      createStudentsModal,
      deleteStudentsModal,
      students,
      editStudentData,
      deleteStudentData,
      pickTemplateModal,
      studentId,
      studentsCount,
      limit,
      textFilter,
    } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const columns = [
      {
        title: 'Nume',
        dataIndex: 'firstName',
        key: 'firstName',
      },
      {
        title: 'Prenume',
        dataIndex: 'lastName',
        key: 'lastName',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Telefon',
        key: 'phone',
        dataIndex: 'phone',
      },
      {
        title: 'Acțiuni',
        key: 'action',
        render: (item) => (
          <span>
            <Button onClick={() => this.openEdit(item)}>Editează</Button>
            <Divider type="vertical" />
            <Button onClick={() => this.openDeleteStudentsModal(item)}>
              Șterge
            </Button>
            <Divider type="vertical" />
            <Button onClick={() => this.openTemplatePicker(item)}>
              Trimite Forma
            </Button>
          </span>
        ),
      },
    ];

    return (
      <div className="students__content students__content--padding">
        <div className="options">
          <div className="options__search">
            {textFilter && (
              <Button onClick={this.closeSearch}>
                <CloseCircleOutlined className="options__search__btn--cancel" />
              </Button>
            )}
            <Search
              placeholder="Caută Elev"
              onSearch={this.onSearch}
              className="options__search__input"
            />
          </div>
          <div className="options__container-icon">
            <div className="options__container-icon__icon">
              <Icon type="plus-circle" onClick={this.openCreateModal} />
            </div>
            <span className="options__container-icon__iconPopup">
              Adaugă Elev
            </span>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={students}
          pagination={{
            onChange: this.onChangePage,
            total: studentsCount,
            pageSize: limit,
          }}
          rowKey="id"
          className="table"
        />
        <EditStudentModal
          title="Editează Student"
          visible={editStudentsModal}
          onOk={this.editStudent}
          onCancel={this.closeEdit}
          getFieldDecorator={getFieldDecorator}
          initialValue={editStudentData}
        />
        <CreateStudentModal
          title="Adaugă Student"
          visible={createStudentsModal}
          onOk={this.createStudent}
          onCancel={this.closeCreateModal}
          getFieldDecorator={getFieldDecorator}
        />

        <DeleteModal
          title="Delete Student"
          item={deleteStudentData}
          visible={deleteStudentsModal}
          text="Sunteți sigur că doriți să ștergeți acest student ?"
          onOk={this.deleteStudent}
          onCancel={this.closeDeleteStudentsModal}
        />

        <PickTemplateModal
          visible={pickTemplateModal}
          studentId={studentId}
          onCancel={this.closeTemplatePicker}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(LoadingHandler.setLoading(loading)),
});

const WrappedStudentsContainer = Form.create({ name: 'StudentsContainerForm' })(
  withRouter(StudentsContainer)
);

export default connect(null, mapDispatchToProps)(WrappedStudentsContainer);
