import { create } from 'apisauce';
import { createBrowserHistory } from 'history';

import { AppRoutes } from '../Constants/AppRoutes.constant';
import { ENVIRONMENT } from '../Constants/env';
import { AuthHandler } from '../Reducers/Auth.Reducer';

const history = createBrowserHistory();

export class ApiService {
  static baseURL = ENVIRONMENT.PROD;

  static typeJSON = {
    headers: {
      'content-type': 'application/json'
    }
  };

  static typeFormData = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  static authorization;

  static dispatch;

  static api = create({
    baseURL: ApiService.baseURL,
    headers: ApiService.typeJSON.headers
  });

  /**
   *
   * @param {Object} auth
   * @param {string} auth.accessToken
   * @param {string} auth.refreshToken
   * @param {number} auth.expiresIn
   */
  static setAuth(auth) {
    ApiService.authorization = auth;
    ApiService.api.setHeader(
      'Authorization',
      `Bearer ${auth === null ? '' : auth.accessToken}`
    );
  }

  static async refreshToken() {
    const { refreshToken, accessToken } = ApiService.authorization;

    try {
      const token = await ApiService.post(
        `/auth/refresh-token`,
        { refreshToken },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      ApiService.setAuth(token);
      ApiService.authorization = null;

      return token;
    } catch (e) {
      if (e.statusCode === 400) {
        history.push(AppRoutes.login);
        ApiService.authorization = null;
        ApiService.dispatch(AuthHandler.setAuth(null));
      }
    }
  }

  static async validate(request) {
    if (request.status === 401) {
      if (ApiService.authorization) {
        const token = await ApiService.refreshToken();
        const { url, method, params, headers } = request.config;
        const repeat = await ApiService[method](url, params, headers);
        ApiService.dispatch(AuthHandler.setAuth(token));
        ApiService.authorization = token;
        return repeat;
      } else {
        // TODO navigate to auth page
        history.push(AppRoutes.login);
        ApiService.dispatch(AuthHandler.setAuth(null));
        throw request.data;
      }
    }
    if (request.status !== 200 && request.status !== 201) {
      throw request.data;
    }
    return request.data;
  }

  /**
   *
   * @param {string} url
   * @param {*} [params]
   * @param {*} [headers]
   */
  static async get(url, params = null, headers = null) {
    const request = await ApiService.api.get(url, params, headers);
    return ApiService.validate(request);
  }

  /**
   *
   * @param {string} url
   * @param {*} [params]
   * @param {*} [headers]
   */
  static async post(url, params = null, headers = null) {
    const request = await ApiService.api.post(url, params, headers);
    return ApiService.validate(request);
  }

  /**
   *
   * @param {string} url
   * @param {*} [params]
   * @param {*} [headers]
   */
  static async delete(url, params = null, headers = null) {
    const request = await ApiService.api.delete(url, params, headers);
    return ApiService.validate(request);
  }

  /**
   *
   * @param {string} url
   * @param {*} [params]
   * @param {*} [headers]
   */
  static async patch(url, params = null, headers = null) {
    const request = await ApiService.api.patch(url, params, headers);
    return ApiService.validate(request);
  }

  /**
   *
   * @param {string} url
   * @param {*} [params]
   * @param {*} [headers]
   */
  static async put(url, params = null, headers = null) {
    const request = await ApiService.api.put(url, params, headers);
    return ApiService.validate(request);
  }
}

/**
 * @typedef {Object} Token
 * @property {string} accessToken Access Token
 * @property {number} expiresIn Token expire
 * @property {string} refreshToken Refresh token
 */
