import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

const propTypes = {
  statisticsData: PropTypes.object.isRequired,
};

/**
 *
 * @param {object} props
 * @param {object} props.statisticsData
 *
 */

class PieChartHiredRate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  pieChartPrecentage = (dataEntry) => {
    if (
      this.props.statisticsData.groupCount === 0 &&
      dataEntry.title === 'empty'
    )
      return 'No Data';
    if (dataEntry.title !== 'undefined')
      return `${Math.round(dataEntry.percentage)}%`;

    return '';
  };

  render() {
    const { statisticsData } = this.props;
    if (statisticsData.groupCount === 0) statisticsData.hired = 1;

    return (
      <PieChart
        style={{ width: '120px' }}
        animate={true}
        label={({ dataEntry }) => `${this.pieChartPrecentage(dataEntry)}`}
        labelStyle={{ fill: '#fff', fontSize: '12px' }}
        data={[
          {
            title:
              statisticsData.groupCount === 0
                ? 'empty'
                : statisticsData.hired === 0
                ? 'undefined'
                : 'Employed',
            value: statisticsData.hired,
            color: '#69B8F1',
          },
          {
            title: statisticsData.unemployed === 0 ? 'undefined' : 'Unemployed',
            value: statisticsData.unemployed,
            color: '#D37575',
          },
        ]}
      />
    );
  }
}

PieChartHiredRate.propTypes = propTypes;

export default PieChartHiredRate;
