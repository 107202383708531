export const ROLES = {
  ADMIN: 1,
  LEADER: 2,
  MODERATOR: 3,
};

export const ROLES_TRANSLATION = {
  ADMIN: 'Admin',
  LEADER: 'Șef Grupă',
};
