import './Navbar.Styles.scss';

import { Icon, Menu } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { AppRoutes } from '../../Constants/AppRoutes.constant';
import { AuthHandler } from '../../Reducers/Auth.Reducer';
import { UserHandler } from '../../Reducers/User.Reducer';
import { AlertService } from '../../Services/Alert.Service';
import { ApiService } from '../../Services/Api.Service';
import { MENU_ADMIN_ROUTES } from './Navbar.Routes';

const propTypes = {
  defaultSelectedKeys: PropTypes.string.isRequired,
  defaultOpenKeys: PropTypes.string.isRequired,
};

/**
 * @param {object} props
 */

function NavbarComponent(props) {
  const { defaultSelectedKeys, defaultOpenKeys, setUser, setAuth } = props;

  async function logOut() {
    try {
      setAuth(null);
      setUser(null);
      ApiService.setAuth(null);
    } catch (e) {
      AlertService.error(e);
    }
  }

  return (
    <Menu
      className="navbar"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      mode="inline"
    >
      {props.user && props.user.roleId === 2 ? (
        <Menu.Item key={AppRoutes.groups}>
          <Link to={AppRoutes.groups}>
            <div>
              <Icon type="user" />
              <span>Grupe</span>
            </div>
          </Link>
        </Menu.Item>
      ) : (
        MENU_ADMIN_ROUTES.map((item, key) => (
          <Menu.Item key={item.route}>
            <Link to={item.route}>
              <div>
                <Icon type={item.icon} />
                <span>{item.routeName}</span>
              </div>
            </Link>
          </Menu.Item>
        ))
      )}
      <Menu.Item key={AppRoutes.profile}>
        <Link to={AppRoutes.profile}>
          <div>
            <Icon type="user" />
            <span>Profil</span>
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="Logout" onClick={logOut}>
        <Icon type="logout" />
        <span className="nav-text">Delogare</span>
      </Menu.Item>
    </Menu>
  );
}

NavbarComponent.propTypes = propTypes;

const navigationState = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(UserHandler.setUser(user)),
  setAuth: (auth) => dispatch(AuthHandler.setAuth(auth)),
});

export default connect(navigationState, mapDispatchToProps)(NavbarComponent);
