import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  setLoading: ['loading'],
});

const ACTIONS = {
  setLoading: (state, { loading }) => ({ ...state, loading }),
};

const INITIAL_STATE = Immutable({
  loading: false,
});

const HANDLERS = {
  [Types.SET_LOADING]: ACTIONS.setLoading,
};

export const LoadingReducer = createReducer(INITIAL_STATE, HANDLERS);

export const LoadingTypes = Types;
export const LoadingHandler = Creators;
