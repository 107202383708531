import { Form, Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import FormInput from '../FormItems/FormInput.Component';

const propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  speciality: PropTypes.object.isRequired,
};

/**
 *
 * @param {object} props
 * @param {boolean} props.visible
 * @param {any} props.onOk
 * @param {any} props.onCancel
 * @param {any} props.getFieldDecorator
 * @param {any} props.speciality
 *
 */
function EditSpecialityModalComponent(props) {
  const { visible, onOk, onCancel, getFieldDecorator, speciality } = props;

  return (
    <Modal
      title="Editare Specialitate"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }}>
        <FormInput
          label="Denumire"
          getFieldDecorator={getFieldDecorator}
          decoratorKey="name"
          message="Introdu denumirea"
          initialValue={speciality.name}
        />
      </Form>
    </Modal>
  );
}

EditSpecialityModalComponent.propTypes = propTypes;

export default EditSpecialityModalComponent;
