export class ValidationService {
  /**
   * @param {string} text
   */
  static isEmpty(text) {
    return /^\s+$/.test(text) || text.length === 0;
  }
  /**
   *
   * @param {object} form
   *
   */

  static validateForm(form) {
    return new Promise((resolve, reject) =>
      form.validateFields((err, values) => {
        if (err) reject(err);
        resolve(values);
      })
    );
  }
}
