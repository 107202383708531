import './MyProfile.Styles.scss';

import { UserOutlined } from '@ant-design/icons';
import { Avatar, Form } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import EditProfileModal from '../../Components/EditProfileModal/EditProfileModal.Component';
import { ROLES_TRANSLATION } from '../../Constants/Roles.constant';
import { LoadingHandler } from '../../Reducers/Loading.Reducer';
import { AlertService } from '../../Services/Alert.Service';
import { UserService } from '../../Services/User.Service';
import { ValidationService } from '../../Services/Validation.Service';

class MyProfileContainer extends Component {
  state = {
    modal: false,
    userData: null,
    loading: true,
    photo: null,
  };

  componentDidMount = () => {
    this.props.setLoading(true);
    this.getMe();
  };

  closeModal = () => this.setState({ modal: false });

  openModal = () => this.setState({ modal: true });

  sendAvatarToContainer = (photo) => this.setState({ photo });

  editUser = async () => {
    const { form } = this.props;
    const { userData, photo } = this.state;
    const newValues = await ValidationService.validateForm(form);

    try {
      let path = '';

      console.log(photo, 'p');
      if (photo !== null) {
        path = new URL(photo.image).pathname;
      }

      let user = { ...userData, ...newValues };
      console.log(user.avatar);

      user.avatar = path;
      const params = {
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        position: user.position,
      };
      if (photo) {
        params.avatar = path;
      }
      await UserService.editCurrentUser(params);
      this.setState({ userData: user }, this.closeModal);
      this.getMe();
    } catch (e) {
      AlertService.error(e);
    }
  };

  getMe = async () => {
    try {
      const user = await UserService.me();
      this.props.setLoading(false);
      this.setState({ userData: user, loading: false });
    } catch (e) {
      AlertService.error(e);
    }
  };

  render() {
    const { userData, loading, modal } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="content">
        {!loading && (
          <div className="content">
            <div className="content__header" />
            <Avatar
              src={userData.avatar ? userData.avatar : ''}
              size={170}
              icon={<UserOutlined />}
              className="content__avatar"
            />
            <p className="content__personName">
              {userData.firstName} {userData.lastName}
            </p>

            <p className="content__personRole">
              {ROLES_TRANSLATION[userData.role.name]}
            </p>
            <button className="button button--edit" onClick={this.openModal}>
              Editează
            </button>
            <EditProfileModal
              visible={modal}
              onCancel={this.closeModal}
              onOk={this.editUser}
              initialValue={userData}
              getFieldDecorator={getFieldDecorator}
              sendAvatarToContainer={this.sendAvatarToContainer}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(LoadingHandler.setLoading(loading)),
});
const WrappedMyProfileContainer = Form.create({
  name: 'MyProfileContainerForm',
})(MyProfileContainer);

export default connect(null, mapDispatchToProps)(WrappedMyProfileContainer);
