import { ApiService } from './Api.Service';

export class StudentsService {
  /**
   *
   * @param {object} studentData
   * @param {number} studentData.studentId
   * @param {number} studentData.templateId
   */
  static sendToStudent(studentData) {
    return ApiService.post('mail/student', studentData);
  }

  /**
   *
   * @param {object} data
   * @param {string} data.textFilter
   * @param {number} data.limit
   * @param {number} data.offset
   * @param {number} data.groupId
   */
  static list(data) {
    return ApiService.get('/student', data);
  }

  /**
   *
   * @param {Object} data
   * @param {string} data.name
   * @param {string} data.year
   * @param {string} data.leaderId
   */
  static create(data) {
    return ApiService.post('/student', data);
  }
  /**
   *
   * @param {Object} data
   * @param {number} data.id
   * @param {string} data.name
   * @param {string} data.year
   * @param {string} data.leaderId
   */
  static edit(data) {
    return ApiService.patch('/student', data);
  }

  /**
   *
   * @param {number} id
   */
  static delete(id) {
    return ApiService.delete(`/student/${id}`);
  }
}
