import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

const propTypes = {
  statisticsData: PropTypes.object.isRequired,
};

/**
 *
 * @param {object} props
 * @param {object} props.statisticsData
 *
 */

class PieChartHiredDomain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  pieChartPrecentage = (dataEntry) => {
    if (
      this.props.statisticsData.groupCount === 0 &&
      dataEntry.title === 'empty'
    )
      return 'No Data';
    if (dataEntry.title !== 'undefined')
      return `${Math.round(dataEntry.percentage)}%`;

    return '';
  };

  render() {
    const { statisticsData } = this.props;

    if (statisticsData.groupCount === 0) statisticsData.hiredInDomain = 1;

    return (
      <PieChart
        style={{ width: '120px' }}
        animate={true}
        label={({ dataEntry }) => `${this.pieChartPrecentage(dataEntry)}`}
        labelStyle={{ fill: '#fff', fontSize: '12px' }}
        data={[
          {
            title:
              statisticsData.hired === 0
                ? 'empty'
                : statisticsData.hiredInDomain === 0
                ? 'undefined'
                : 'In domeniu',
            value: statisticsData.hiredInDomain,
            color: '#69B8F1',
          },
          {
            title:
              statisticsData.hiredAnotherDomain === 0
                ? 'undefined'
                : 'In alt domeniu',
            value: statisticsData.hiredAnotherDomain,
            color: '#D37575',
          },
        ]}
      />
    );
  }
}

PieChartHiredDomain.propTypes = propTypes;

export default PieChartHiredDomain;
