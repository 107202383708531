import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  label: PropTypes.string.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  decoratorKey: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  initialValue: PropTypes.object,
  selectValues: PropTypes.array.isRequired,
  onChangeRole: PropTypes.func,
};

const { Option } = Select;

/**
 *
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.message
 * @param {any} props.getFieldDecorator
 * @param {string} props.decoratorKey
 * @param {boolean} props.required
 * @param {object} [props.initialValue]
 * @param {Array} props.selectValues
 * @param {any} props.onChangeRole
 *
 */

function FormSelect(props) {
  const {
    label,
    getFieldDecorator,
    decoratorKey,
    required,
    initialValue,
    selectValues,
    onChangeRole,
  } = props;

  return (
    <Form.Item label={label}>
      {decoratorKey === 'leaderId' &&
        getFieldDecorator(decoratorKey, {
          initialValue:
            initialValue && selectValues.length && initialValue.leader.id,
          rules: [{ required: required }],
        })(
          <Select>
            {selectValues &&
              selectValues.map((item, key) => (
                <Option value={item.id} key={item.id}>
                  {item.firstName} {item.lastName}
                </Option>
              ))}
          </Select>
        )}

      {decoratorKey === 'specialityId' &&
        getFieldDecorator(decoratorKey, {
          initialValue:
            initialValue && initialValue.speciality
              ? initialValue.speciality.id
              : undefined,
          rules: [{ required: required }],
        })(
          <Select>
            {selectValues &&
              selectValues.map((item, key) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        )}

      {decoratorKey === 'roleId' &&
        getFieldDecorator(decoratorKey, {
          initialValue: initialValue && initialValue,
          rules: [{ required: required }],
        })(
          <Select onChange={onChangeRole}>
            {selectValues &&
              selectValues.map((item, key) => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
          </Select>
        )}
    </Form.Item>
  );
}

FormSelect.defaultProps = {
  initialValue: null,
  required: true,
};

FormSelect.propTypes = propTypes;

export default FormSelect;
