import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

// const propTypes = {
//   statisticsData: PropTypes.object.isRequired,
// };

/**
 *
 * @param {object} props
 * @param {object} props.statisticsData
 *
 */

function LineChartSatisfaction(props) {
  const [data, setData] = useState({});

  useEffect(() => {
    let years = [];
    let satisfactions = [];

    if (props.statisticsData !== undefined && props.statisticsData !== null) {
      props.statisticsData.forEach((data) => {
        const sum =
          data.bestCount + data.goodCount + data.okCount + data.badCount;
        const score =
          Math.round(
            (data.bestCount * 10 +
              data.goodCount * 7 +
              data.okCount * 5 +
              data.badCount * 2) /
              sum
          ) * 10;

        satisfactions = [...satisfactions, score];
        years = [...years, data.year];
      });

      const data = {
        labels: years,
        datasets: [
          {
            label:
              'Feedback al elevilor specialitatii per ani ( 100 - pozitiv, 0 - negativ )',
            data: satisfactions,
            fill: false,
            borderColor: '#69B8F1',
          },
        ],
      };
      setData(data);
    } else {
      const data = {
        labels: [],
        datasets: [
          {
            label: 'Angajati in domeniu din aceasta specialitate',
            data: [],
            fill: false,
            borderColor: '#69B8F1',
          },
        ],
      };
      setData(data);
    }
  }, [props.statisticsData]);

  let options = {
    scales: {
      yAxes: [
        {
          ticks: {
            max: 100,
            min: 0,
          },
        },
      ],
    },
  };

  return <Line width={300} height={130} data={data} options={options} />;
}

export default LineChartSatisfaction;
