import { ApiService } from './Api.Service';

export class StatisticsService {
  /**
   *
   *
   *
   */
  static getRaport(year) {
    return ApiService.get(`/statistics/report?year=${year}`);
  }

  /**
   *
   *
   *
   */
  static getYears() {
    return ApiService.get('/statistics/year/distinct');
  }

  /**
   *
   *
   *
   */

  static getGlobalStatistics(data) {
    return ApiService.get('/statistics/global', data);
  }

  /**
   *
   *
   *
   */

  static getYear(year) {
    return ApiService.get('/statistics/year', year);
  }

  /**
   *
   *
   *
   */

  static getGroupStatistics(groupId) {
    return ApiService.get('/statistics', groupId);
  }

  /**
   *
   *
   *
   */

  static send(data) {
    return ApiService.post('/statistics', data);
  }

  /**
   *
   *
   *
   */

  static verify(token) {
    return ApiService.get('/statistics/verify', { token });
  }
}
