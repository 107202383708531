import { ApiService } from './Api.Service';

export class SpecialityService {
  /**
   *
   * @param {number} id
   */
  static delete(id) {
    return ApiService.delete(`/speciality/${id}`);
  }

  /**
   *
   * @param {object} data
   * @param {string} [data.textFilter]
   */
  static list(data) {
    return ApiService.get('/speciality', data);
  }

  /**
   *
   * @param {Object} data
   * @param {string} data.name
   */
  static create(data) {
    return ApiService.post('/speciality', data);
  }

  /**
   *
   * @param {Object} data
   * @param {number} data.id
   * @param {string} data.name
   */
  static edit(data) {
    return ApiService.patch('/speciality', data);
  }
}
