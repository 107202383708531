import { ApiService } from './Api.Service';

export class UserService {
  static me() {
    return ApiService.get('/account/me');
  }

  static availableLeaders() {
    return ApiService.get('/account/leaders');
  }

  static uploadAvatar(data) {
    return ApiService.post('/file/upload', data);
  }

  static edit(data) {
    return ApiService.patch('/account', data);
  }

  static editCurrentUser(data) {
    return ApiService.patch('/account/me', data);
  }

  static delete(id) {
    return ApiService.delete(`/account/${id}`);
  }

  static create(data) {
    return ApiService.post('/account', data);
  }

  static list(data) {
    return ApiService.get('/account', data);
  }
}
