import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { AuthReducer } from './Auth.Reducer';
import { LoadingReducer } from './Loading.Reducer';
import { UserReducer } from './User.Reducer';

const reducers = combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  loading: LoadingReducer,
});

const config = {
  key: 'index',
  blacklist: [],
  storage,
};

export const IndexReducer = persistReducer(config, reducers);
