import { Form, Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import FormInput from '../FormItems/FormInput.Component';

const propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
};

/**
 *
 * @param {object} props
 * @param {string} props.title
 * @param {boolean} props.visible
 * @param {any} props.onOk
 * @param {any} props.onCancel
 * @param {any} props.getFieldDecorator
 *
 */
function CreateStudentModal(props) {
  const { title, visible, onOk, onCancel, getFieldDecorator } = props;

  return (
    <Modal title={title} visible={visible} onOk={onOk} onCancel={onCancel}>
      <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }}>
        <FormInput
          label="Prenume"
          decoratorKey="firstName"
          message="Numele elevului"
          getFieldDecorator={getFieldDecorator}
        />

        <FormInput
          label="Nume"
          decoratorKey="lastName"
          message="Prenumele elevului"
          getFieldDecorator={getFieldDecorator}
        />

        <FormInput
          label="Email"
          decoratorKey="email"
          message="Introdu e-mail-ul elevului"
          getFieldDecorator={getFieldDecorator}
        />

        <FormInput
          label="Telefon"
          decoratorKey="phone"
          message="Introdu telefonul elevului"
          getFieldDecorator={getFieldDecorator}
        />
      </Form>
    </Modal>
  );
}

CreateStudentModal.propTypes = propTypes;

export default CreateStudentModal;
