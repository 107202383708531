import { createActions, createReducer } from 'reduxsauce';
import Immutable from 'seamless-immutable';

const { Types, Creators } = createActions({
  setUser: ['user']
});

const ACTIONS = {
  setUser: (state, { user }) => ({ ...state, user })
};

const INITIAL_STATE = Immutable({
  user: null
});

const HANDLERS = {
  [Types.SET_USER]: ACTIONS.setUser
};

export const UserReducer = createReducer(INITIAL_STATE, HANDLERS);

export const UserTypes = Types;
export const UserHandler = Creators;
