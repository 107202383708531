import { AppRoutes } from '../../Constants/AppRoutes.constant';

export const MENU_ADMIN_ROUTES = [
  {
    route: AppRoutes.groups,
    icon: 'usergroup-add',
    routeName: 'Grupe',
  },
  {
    route: AppRoutes.users,
    icon: 'team',
    routeName: 'Utilizatori',
  },
  {
    route: AppRoutes.specialities,
    icon: 'solution',
    routeName: 'Specialități',
  },
  {
    route: AppRoutes.statistics,
    icon: 'pie-chart',
    routeName: 'Statistici',
  },
  {
    route: AppRoutes.templateList,
    icon: 'control',
    routeName: 'Șabloane',
  },
];
