import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

/**
 *
 * @param {object} props
 * @param {object} props.statisticsData
 *
 */

function LineChartHired(props) {
  const [data, setData] = useState({});

  useEffect(() => {
    let years = [];
    let hired = [];
    if (props.statisticsData !== undefined && props.statisticsData !== null) {
      props.statisticsData.forEach((data) => {
        years = [...years, data.year];
        hired = [...hired, data.hiredInDomain];
      });

      const data = {
        labels: years,
        datasets: [
          {
            label: 'Angajati in domeniu IT din aceasta specialitate',
            data: hired,
            fill: false,
            borderColor: '#69B8F1',
          },
        ],
      };
      setData(data);
    } else {
      const data = {
        labels: [],
        datasets: [
          {
            label: 'Angajati in domeniu IT din aceasta specialitate',
            data: [],
            fill: false,
            borderColor: '#69B8F1',
          },
        ],
      };
      setData(data);
    }
  }, [props.statisticsData]);

  let options = {
    scales: {
      yAxes: [
        {
          ticks: {
            // max: 100,
            // min: 0,
            stepSize: 1,
          },
        },
      ],
    },
  };

  return <Line width={300} height={130} data={data} options={options} />;
}

export default LineChartHired;
