import { ApiService } from './Api.Service';

export class GroupService {
  /**
   *
   */
  static listAllGroups() {
    return ApiService.get('group/list');
  }

  /**
   *
   * @param {object} data
   * @param {number} data.groupId
   * @param {number} data.templateId
   */
  static sendToGroup(data) {
    return ApiService.post('mail/group', data);
  }

  /**
   *
   * @param {object} data
   * @param {string} data.textFilter
   * @param {number} data.limit
   * @param {number} data.offset
   */
  static list(data) {
    return ApiService.get('/group', data);
  }

  /**
   *
   *
   */

  static allGroups() {
    return ApiService.get('/group/list');
  }

  //

  /**
   *
   * @param {Object} data
   * @param {string} data.name
   * @param {string} data.year
   * @param {string} data.leaderId
   */
  static create(data) {
    return ApiService.post('/group', data);
  }
  /**
   *
   * @param {Object} data
   * @param {number} data.id
   * @param {string} data.name
   * @param {string} data.year
   * @param {string} data.leaderId
   */
  static edit(data) {
    return ApiService.patch('/group', data);
  }

  /**
   *
   * @param {number} id
   */
  static delete(id) {
    return ApiService.delete(`/group/${id}`);
  }
}
