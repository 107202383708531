import './PickTemplate.styles.scss';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

const propTypes = {
  item: PropTypes.object.isRequired,
  selectItem: PropTypes.func.isRequired,
};

/**
 *
 * @param {object} props
 * @param {object} props.item
 * @param {any} props.selectItem
 *
 */

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { item, selectItem } = this.props;
    return (
      <div className="items__template">
        <div className="template__content">
          <div className="template__radioSelector"></div>
          <div className="template__author">
            <p className="template--font-gorditaMedium">Autor</p>
            <p className="template--font-gorditaRegular">
              {item.account.firstName} {item.account.lastName}
            </p>
          </div>
          <div className="template__text">
            <p className="template--font-gorditaMedium">Text</p>
            <p
              className="template--font-gorditaRegular template__text--overflow"
              dangerouslySetInnerHTML={{ __html: item.text }}
            ></p>
          </div>
        </div>
        <div>
          <button
            key={item.id}
            className={
              item.selected
                ? 'template__pickButtonSelected'
                : 'template__pickButton'
            }
            id={item.id}
            onClick={() => {
              selectItem(item.id);
            }}
          >
            <p>{item.selected ? 'Ales' : 'Alege'}</p>
          </button>
        </div>
      </div>
    );
  }
}

Template.propTypes = propTypes;

export default Template;
