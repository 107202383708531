import './PickTemplate.styles.scss';

import { Modal, Pagination } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { AlertService } from '../../Services/Alert.Service';
import { GroupService } from '../../Services/Group.Service';
import { StudentsService } from '../../Services/Students.Service';
import { TemplateService } from '../../Services/Template.Service';
import Template from './Template.Component';

const propTypes = {
  visible: PropTypes.bool.isRequired,
  groupId: PropTypes.number,
  studentId: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
};

/**
 *
 * @param {object} props
 * @param {boolean} props.visible
 * @param {number} props.groupId
 * @param {any} props.onCancel
 *
 */

class PickTemplateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      templates: [],
      template: {},
      limit: 3,
      offset: 0,
      textFilter: null,
      count: 0,
      total: 0,
      page: 1,
      templateId: null,
      groupId: null,
      studentId: null,
    };
  }

  componentDidMount = () => {
    this.getTemplates();
  };

  onChangePageTemplate = (page) => {
    const { limit } = this.state;
    return this.setState(
      {
        page: page,
        offset: limit * page - limit,
      },
      this.getTemplates
    );
  };

  getTemplates = async () => {
    const { limit, offset, textFilter } = this.state;

    const { data, count } = await TemplateService.list({
      limit,
      offset,
      textFilter,
    });
    this.setState({
      templates: data,
      count: count,
    });
  };

  sendGroupForm = async () => {
    const sendData = {
      groupId: this.props.groupId,
      templateId: this.state.templateId,
    };

    if (sendData.templateId === null) {
      AlertService.error('Alege un template');
      return null;
    }

    try {
      await GroupService.sendToGroup(sendData);
    } catch (e) {
      AlertService.error(e);
    }

    this.setState({ pickTemplateModal: false }, this.props.onCancel);
  };

  sendStudentForm = async () => {
    const sendData = {
      studentId: this.props.studentId,
      templateId: this.state.templateId,
    };

    try {
      await StudentsService.sendToStudent(sendData);
    } catch (e) {
      AlertService.error(e);
    }

    this.setState({ pickTemplateModal: false }, this.props.onCancel);
  };

  selectItem = (id) => {
    let { templates } = this.state;
    const foundIndex = templates.findIndex((item) => item.id === id);
    templates = templates.map((item) =>
      Object.assign(item, { selected: false })
    );
    templates[foundIndex].selected = true;
    this.setState({ templates, templateId: id });
  };

  sendForm = () => {
    this.props.groupId ? this.sendGroupForm() : this.sendStudentForm();
    const templates = this.state.templates.map((item) => ({
      ...item,
      selected: false,
    }));
    return this.setState({ templates });
  };

  render() {
    const { visible, onCancel } = this.props;
    const { templates, count, page, limit } = this.state;

    return (
      <Modal
        width="800px"
        title="Alege un șablon"
        visible={visible}
        onOk={this.sendForm}
        onCancel={onCancel}
        okText="Trimite Forma"
        cancelText="Anulează"
      >
        <div className="modalBlock">
          <div className="items">
            {templates &&
              templates.map((item) => (
                <Template
                  item={item}
                  selectItem={this.selectItem}
                  key={item.id}
                />
              ))}
          </div>

          <div className="pagination">
            <Pagination
              defaultCurrent={page}
              total={count}
              pageSize={limit}
              onChange={this.onChangePageTemplate}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

PickTemplateModal.propTypes = propTypes;

export default PickTemplateModal;
