export const AppRoutes = {
  login: '/',
  categories: '/categories',
  groups: '/groups',
  users: '/users',
  specialities: '/specialities',
  colleges: '/colleges',
  statistics: '/statistics',
  students: '/students',
  studentform: '/studentform',
  templateEdit: '/template/edit',
  templateList: '/templates',
  profile: '/profile',
};
