import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  item: PropTypes.object,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

/**
 *
 * @param {object} props
 * @param {object} props.item
 * @param {string} props.title
 * @param {string} props.text
 * @param {boolean} props.visible
 * @param {any} props.onOk
 * @param {any} props.onCancel
 *
 */
function DeleteModal(props) {
  const { title, text, visible, onOk, onCancel, item } = props;

  function confirm() {
    onOk(item.id);
  }

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={confirm}
      onCancel={onCancel}
      okText="Da"
      cancelText="Anulează"
      okType="danger"
    >
      <p>{text}</p>
    </Modal>
  );
}

DeleteModal.propTypes = propTypes;

export default DeleteModal;
