import { ApiService } from './Api.Service';

export class AuthService {
  /**
   * @param {object} data
   * @param {string} data.email
   * @param {string} data.password
   */
  static login({ email, password }) {
    return ApiService.post('/auth/login', { email, password });
  }
}
