import './Editor.Styles.scss';

import { Button, Input } from 'antd';
import React, { Component } from 'react';
import Frame from 'react-frame-component';
import ReactQuill from 'react-quill';
import { withRouter } from 'react-router-dom';

import { AlertService } from '../../Services/Alert.Service';
import { TemplateService } from '../../Services/Template.Service';

class EditorContainer extends Component {
  state = {
    title: '',
    text: 'Sample Text',
    defaultTemplate: '',
    template: '',
    editMode: false,
    id: null,
  };

  componentDidMount = () => {
    this.getTemplate();
    if (this.props.location.state) {
      const { item } = this.props.location.state;
      const { id, text, title } = item;
      this.setText(text);
      return this.setState({ id, text, title, editMode: true });
    }
    return;
  };

  setText = (text) => {
    try {
      const { defaultTemplate } = this.state;
      const format = defaultTemplate.replace('{{textTarget}}', text);
      this.setState({ template: format, text });
    } catch (e) {
      AlertService.error(e);
    }
  };

  onChangeTitle = (event) => {
    const { value } = event.target;
    return this.setState({ title: value });
  };

  getTemplate = async () => {
    try {
      const { template } = await TemplateService.sample();

      this.setState({ defaultTemplate: template, template }, () => {
        if (this.state.text) this.setText(this.state.text);
      });
    } catch (e) {
      AlertService.error(e.message);
    }
  };
  createTemplate = async () => {
    const { title, text } = this.state;
    try {
      await TemplateService.create({ title, text });
      this.props.history.goBack();
    } catch (e) {
      AlertService.error(e);
    }
  };

  editTemplate = async () => {
    const { id, title, text } = this.state;
    try {
      await TemplateService.edit({ id, title, text });
      this.props.history.goBack();
    } catch (e) {
      AlertService.error(e);
    }
  };

  cancelTemplate = () => {
    this.props.history.goBack();
  };

  render() {
    const { title, text, template, editMode, defaultTemplate } = this.state;
    return (
      <div className="content-editor">
        <div className="template">
          <div className="template-divider" />
          <Frame className="template-frame">
            <div
              dangerouslySetInnerHTML={{ __html: template }}
              className="template-frame--mail"
            />
          </Frame>
        </div>
        <div className="editor">
          <p className="editor-title">
            {editMode ? 'Editează Șablon' : 'Crează Șablon'}
          </p>
          <div>
            <p className="editor-label">Titlu</p>
            <Input
              className="editor-input"
              placeholder="ex: Pentru Contabilitate"
              value={title}
              onChange={this.onChangeTitle}
              minLength={1}
            />

            <p className="editor-label">Conținut</p>
            {defaultTemplate && (
              <ReactQuill
                value={text}
                onChange={this.setText}
                theme="snow"
                className="editor-quill"
              />
            )}

            <div className="buttons">
              <Button
                className="button-submit"
                onClick={editMode ? this.editTemplate : this.createTemplate}
              >
                {editMode ? 'Editează' : 'Crează'}
              </Button>
              <Button className="button-cancel" onClick={this.cancelTemplate}>
                Anulează
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditorContainer);
