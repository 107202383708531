import './StudentForm.Styles.scss';
import 'antd/dist/antd.css';

import { Button, Form, Input, Radio } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { LoadingHandler } from '../../Reducers/Loading.Reducer';
import { AlertService } from '../../Services/Alert.Service';
import { StatisticsService } from '../../Services/Statistics.Service';
import { ValidationService } from '../../Services/Validation.Service';

class StudentFormContainer extends Component {
  state = {
    employmentInputVisible: false,
    otherOptionsVisible: false,
    tokenExists: false,
    tokenIsCompleted: false,
    tokenParam: null,
    data: {
      hiredInDomain: false,
      hired: false,
      continueStudy: false,
      workPlace: '',
      militaryService: false,
      childCare: false,
      goneAbroad: false,
      antreprenor: false,
      other: false,
      satisfactionLevelId: 0,
    },
  };

  componentDidMount = async () => {
    this.props.setLoading(false);
    try {
      var queryString = window.location.search;
      const tokenParam = queryString.substring(7, queryString.length);
      const response = await StatisticsService.verify(tokenParam);
      this.setState({
        tokenParam,
        tokenExists: response.exists,
        tokenIsCompleted: response.isCompleted,
      });
    } catch (e) {
      AlertService.error(e);
    }
  };

  collectData = async () => {
    const { form } = this.props;
    const { data } = this.state;
    const value = await ValidationService.validateForm(form);

    switch (value.professionalActivity) {
      case 'hiredInDomain':
        data.hiredInDomain = true;
        data.hired = true;
        break;
      case 'hired':
        data.hired = true;
        break;
      case 'continueStudy':
        data.continueStudy = true;
        break;
      default:
        break;
    }

    data.workPlace = value.workPlace;

    if (typeof value.moreWorkplaceOptions !== 'undefined')
      switch (value.moreWorkplaceOptions) {
        case 'unemployed':
          break;
        case 'militaryService':
          data.militaryService = true;
          break;
        case 'childCare':
          data.childCare = true;
          break;
        case 'goneAbroad':
          data.goneAbroad = true;
          break;
        case 'antreprenor':
          data.antreprenor = true;
          data.hired = true;
          break;
        case 'other':
          data.other = true;
          break;
        default:
          break;
      }

    switch (value.satisfactionLevelId) {
      case '1':
        data.satisfactionLevelId = 1;
        break;
      case '2':
        data.satisfactionLevelId = 2;
        break;
      case '3':
        data.satisfactionLevelId = 3;
        break;
      case '4':
        data.satisfactionLevelId = 4;
        break;
      default:
        break;
    }

    this.setState({
      data,
      tokenIsCompleted: true,
    });
    this.sendData();
  };

  changeEmployedInputVisibility = () =>
    this.setState({
      employmentInputVisible: true,
      otherOptionsVisible: false,
    });

  changeRadioInputVisibility = () =>
    this.setState({
      employmentInputVisible: false,
      otherOptionsVisible: true,
    });

  sendData = async () => {
    const { data } = this.state;
    const sendData = {
      token: this.state.tokenParam,
      ...data,
    };

    try {
      await StatisticsService.send(sendData);
    } catch (e) {
      AlertService.error(e);
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      employmentInputVisible,
      otherOptionsVisible,
      tokenIsCompleted,
      tokenExists,
    } = this.state;

    return (
      <div className="main-content">
        {tokenIsCompleted ? (
          <div className="thanks-page">
            <div className="thanks-page__header">
              <img
                src="ceiti_logo.png"
                className="thanks-page__header__logo"
                alt="ceiti_logo"
              />
            </div>
            <div className="thanks-page__thanks-message">
              <p className="thanks-page__thanks-message--completed">
                Formularul a fost completat.
              </p>
              <p className="thanks-page__thanks-message--thanks">
                Mulțumim pentru timpul acordat !
              </p>
              <iframe
                src="https://giphy.com/embed/26gN27K98gXfnvEJy"
                frameBorder="0"
                className="giphy-embed gif"
                title="gif"
              ></iframe>
              {/* <p>
                <a href="https://giphy.com/gifs/hbo-harry-potter-26gN27K98gXfnvEJy"></a>
              </p> */}
            </div>
          </div>
        ) : (
          <div className="form-page">
            {tokenExists ? (
              <div className="form-content">
                <div className="form-content__header">
                  <img
                    src="ceiti_logo.png"
                    className="form-content__header__logo"
                    alt="ceiti-logo"
                  />
                </div>
                <div className="form-content__radio-page">
                  <Form>
                    <div className="employment-content--radios">
                      <Form.Item className="employment-content__form">
                        <p className="employment-content__form__title">
                          Alege activitatea ta profesionala la acest moment:
                        </p>
                        {getFieldDecorator('professionalActivity', {
                          rules: [
                            {
                              required: true,
                              message: 'Alege o variantă din cele de mai sus',
                            },
                          ],
                        })(
                          <div>
                            <Radio.Group name="employmentStatus">
                              <Radio.Button
                                className="employment-content__form__button--hired"
                                value="hiredInDomain"
                                onClick={this.changeEmployedInputVisibility}
                              >
                                Angajat in domeniu
                              </Radio.Button>
                              <Radio.Button
                                className="employment-content__form__button--otherDomain"
                                value="hired"
                                onClick={this.changeEmployedInputVisibility}
                              >
                                Angajat in alt domeniu
                              </Radio.Button>
                              <Radio.Button
                                className="employment-content__form__button--continueStudy"
                                value="continueStudy"
                                onClick={this.changeEmployedInputVisibility}
                              >
                                Continui Studiile
                              </Radio.Button>

                              <Radio.Button
                                className="employment-content__form__button--others"
                                onClick={this.changeRadioInputVisibility}
                                value="others"
                              >
                                Altceva
                              </Radio.Button>
                            </Radio.Group>
                          </div>
                        )}
                        {employmentInputVisible && (
                          <Form.Item>
                            {getFieldDecorator('workPlace', {
                              rules: [
                                {
                                  required: true,
                                  message:
                                    'Introdu compania în care esti angajat/facultatea unde studiezi',
                                },
                              ],
                            })(
                              <Input
                                className="employment-content__form__input--workplace"
                                placeholder="Introdu locul de muncă/universitatea"
                              />
                            )}
                          </Form.Item>
                        )}
                      </Form.Item>
                    </div>
                    <div className="employment-content__radioGroup-others">
                      {otherOptionsVisible && (
                        <Form.Item>
                          {getFieldDecorator('moreWorkplaceOptions', {
                            rules: [
                              {
                                required: true,
                                message: 'Alege una din opțiunile de mai sus',
                              },
                            ],
                          })(
                            <Radio.Group>
                              <div className="radioGroup-others__content">
                                <p>Alege o variantă de mai jos</p>
                                <div className="radioGroup-others__content__radios ">
                                  <div className="radioGroup-others__content__radios--column">
                                    <Radio value="unemployed">Neangajat</Radio>
                                    <Radio value="militaryService">
                                      Serviciu Militar
                                    </Radio>
                                    <Radio value="childCare">
                                      Concediu de ingrijire a copilului
                                    </Radio>
                                  </div>
                                  <div className="radioGroup-others__content__radios--column">
                                    <Radio value="goneAbroad">
                                      Plecat peste hotare
                                    </Radio>
                                    <Radio value="antreprenor">
                                      Afacere Proprie
                                    </Radio>
                                    <Radio value="other">Alte Prevederi</Radio>
                                  </div>
                                </div>
                              </div>
                            </Radio.Group>
                          )}
                        </Form.Item>
                      )}
                    </div>
                    <div className="satisfaction-rating">
                      <Form.Item className="satisfaction-rating__form">
                        <p className="satisfaction-rating__form__title">
                          Selectati feedback-ul în raport cu dezvoltarea
                          profesională oferită de CEITI este:
                        </p>
                        {getFieldDecorator('satisfactionLevelId', {
                          rules: [
                            {
                              required: true,
                              message: 'Introdu nivelul de satisfacție!',
                            },
                          ],
                        })(
                          <Radio.Group>
                            <Radio.Button
                              className="satisfaction-rating__form__item--level-best"
                              value="1"
                            >
                              Foarte bun
                            </Radio.Button>
                            <Radio.Button
                              className="satisfaction-rating__form__item--level-good"
                              value="2"
                            >
                              Bun
                            </Radio.Button>
                            <Radio.Button
                              className="satisfaction-rating__form__item--level-ok"
                              value="3"
                            >
                              Satisfacator
                            </Radio.Button>
                            <Radio.Button
                              className="satisfaction-rating__form__item--level-bad"
                              value="4"
                            >
                              Nesatisfacator
                            </Radio.Button>
                          </Radio.Group>
                        )}
                      </Form.Item>
                    </div>
                    <div className="submit-content">
                      <Form.Item>
                        <Button
                          type="primary"
                          className="submit-content__button--submit"
                          onClick={this.collectData}
                        >
                          Trimite
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            ) : (
              <p>Token Invalid</p>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(LoadingHandler.setLoading(loading)),
});

const WrappedStudentFormContainer = Form.create({
  name: 'StudentFormContainer',
})(StudentFormContainer);

export default connect(null, mapDispatchToProps)(WrappedStudentFormContainer);
