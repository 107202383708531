import { createBrowserHistory } from 'history';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import NavbarComponent from '../Components/NavbarComponent/Navbar.Component';
import { AppRoutes } from '../Constants/AppRoutes.constant';
import EditorContainer from '../Containers/Editor/Editor.Container';
import GroupsContainer from '../Containers/Groups/Groups.Container';
import LoginContainer from '../Containers/Login/Login.Container';
import WrappedMyProfileContainer from '../Containers/MyProfile/MyProfile.Container';
import WrappedSpecialityContainer from '../Containers/Speciality/Speciality.Container';
import StatisticsContainer from '../Containers/Statistics/Statistics.Container';
import WrappedStudentFormContainer from '../Containers/StudentForm/StudentForm.Container';
import StudentsContainer from '../Containers/Students/Students.Container';
import WrappedTemplateList from '../Containers/TemplateList/TemplateList.Container';
import UserContainer from '../Containers/User/User.Container';
import { AuthHandler } from '../Reducers/Auth.Reducer';
import { UserHandler } from '../Reducers/User.Reducer';
import { AlertService } from '../Services/Alert.Service';
import { ApiService } from '../Services/Api.Service';
import { UserService } from '../Services/User.Service';

// import { css } from '@emotion/core';
const history = createBrowserHistory();
const AuthRoutes = [
  {
    path: AppRoutes.groups,
    exact: false,
    adminOnly: false,
    sidebar: () => (
      <div className="flexbox flex__diretion--row">
        <NavbarComponent
          defaultSelectedKeys={AppRoutes.groups}
          defaultOpenKeys={AppRoutes.groups}
        />
        <GroupsContainer />
      </div>
    ),
  },
  {
    path: AppRoutes.users,
    adminOnly: true,
    exact: false,
    sidebar: () => (
      <div className="flexbox flex__diretion--row">
        <NavbarComponent
          defaultSelectedKeys={AppRoutes.users}
          defaultOpenKeys={AppRoutes.users}
        />
        <UserContainer />
      </div>
    ),
  },
  {
    path: AppRoutes.students,
    adminOnly: false,
    exact: false,
    sidebar: () => (
      <div className="flexbox flex__diretion--row">
        <NavbarComponent
          defaultSelectedKeys={AppRoutes.groups}
          defaultOpenKeys={AppRoutes.groups}
        />
        <StudentsContainer />
      </div>
    ),
  },
  {
    path: AppRoutes.specialities,
    adminOnly: true,
    exact: false,
    sidebar: () => (
      <div className="flexbox flex__diretion--row">
        <NavbarComponent
          defaultSelectedKeys={AppRoutes.specialities}
          defaultOpenKeys={AppRoutes.specialities}
        />
        <WrappedSpecialityContainer />
      </div>
    ),
  },
  // {
  //   path: AppRoutes.colleges,
  //   adminOnly: true,
  //   exact: false,
  //   sidebar: () => (
  //     <div className="flexbox flex__diretion--row">
  //       <NavbarComponent
  //         defaultSelectedKeys={AppRoutes.colleges}
  //         defaultOpenKeys={AppRoutes.colleges}
  //       />
  //       <WrappedCollegesContainer />
  //     </div>
  //   ),
  // },
  {
    path: AppRoutes.statistics,
    adminOnly: true,
    exact: false,
    sidebar: () => (
      <div className="flexbox flex__diretion--row">
        <NavbarComponent
          defaultSelectedKeys={AppRoutes.statistics}
          defaultOpenKeys={AppRoutes.statistics}
        />
        <StatisticsContainer />
      </div>
    ),
  },
  {
    path: AppRoutes.templateEdit,
    adminOnly: true,
    exact: false,
    sidebar: () => (
      <div className="flexbox flex__diretion--row">
        <EditorContainer />
      </div>
    ),
  },
  {
    path: AppRoutes.templateList,
    adminOnly: true,
    exact: false,
    sidebar: () => (
      <div className="flexbox flex__diretion--row">
        <NavbarComponent
          defaultSelectedKeys={AppRoutes.templateList}
          defaultOpenKeys={AppRoutes.templateList}
        />
        <WrappedTemplateList />
      </div>
    ),
  },
  {
    path: AppRoutes.profile,
    adminOnly: false,
    exact: false,
    sidebar: () => (
      <div className="flexbox flex__diretion--row">
        <NavbarComponent
          defaultSelectedKeys={AppRoutes.profile}
          defaultOpenKeys={AppRoutes.profile}
        />
        <WrappedMyProfileContainer />
      </div>
    ),
  },
  {
    path: AppRoutes.studentform,
    exact: false,
    sidebar: () => (
      <div className="flexbox flex__diretion--row">
        <WrappedStudentFormContainer />
      </div>
    ),
  },
];

const Routes = [
  {
    path: '/',
    exact: true,
    sidebar: () => (
      <div className="flexbox justify__center align__center height_vh">
        <LoginContainer />
      </div>
    ),
  },
  {
    path: AppRoutes.studentform,
    exact: false,
    sidebar: () => (
      <div className="flexbox flex__diretion--row">
        <WrappedStudentFormContainer />
      </div>
    ),
  },
];

class NavigationMiddleware extends Component {
  componentWillMount = async () => {
    ApiService.dispatch = this.props.dispatch;
    await this.init();
  };

  init = async () => {
    const { auth } = this.props;
    if (!auth) {
      if (history.location.pathname !== '/studentform')
        return history.push(AppRoutes.login);
    }
    ApiService.setAuth(auth);
    if (history.location.pathname!=="/studentform"){
    try {
      await this.getUser();
    } catch (e) {
      AlertService.error(e);
    }
  }
    return;
  };

  getUser = async () => {
    try {

      const user = await UserService.me();

      if (this.checkBlockedUser(user)) {
        this.props.setAuth(null);
        this.props.setUser(null);
        return;
      }

      if (user) {
        this.props.setUser(user);
      }
    } catch (e) {
      AlertService.error(e);
    }
  };

  checkBlockedUser = (user) => user.blocked;

  render() {
    const { auth, user, loading } = this.props;
    return (
      <div>
        {loading && (
          <div
            style={{
              position: 'absolute',
              left: '0',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              width: '100%',
              height: '100%',
              right: '0',
              zIndex: 2,
            }}
          >
            <div
              style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                zIndex: 3,
              }}
            >
              <ClipLoader
                // css={null}
                size={80}
                color={'#399af3b3'}
                loading={loading}
              />
            </div>
          </div>
        )}

        <Router>
          <Switch>
            {auth && user
              ? AuthRoutes.map((route, index) => {
                  if (route.adminOnly && user.role && user.role.id === 2) {
                    return null;
                  }
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      children={route.sidebar}
                    />
                  );
                })
              : Routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    children={route.sidebar}
                  />
                ))}
            {auth ? (
              <Redirect to={AppRoutes.groups} />
            ) : (
              <Redirect to={AppRoutes.login} />
            )}
          </Switch>
        </Router>
      </div>
    );
  }
}

const navigationState = (state) => ({
  user: state.user.user,
  auth: state.auth.auth,
  loading: state.loading.loading,
});

const navigationDispatch = (dispatch) => ({
  dispatch,
  setUser: (user) => dispatch(UserHandler.setUser(user)),
  setAuth: (auth) => dispatch(AuthHandler.setAuth(auth)),
});

export default connect(
  navigationState,
  navigationDispatch
)(NavigationMiddleware);
