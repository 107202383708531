import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  label: PropTypes.string.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
  decoratorKey: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  required: PropTypes.bool.isRequired,
};

/**
 *
 * @param {object} props
 * @param {string} props.label
 * @param {any} props.getFieldDecorator
 * @param {string} props.decoratorKey
 * @param {string} props.message
 * @param {string} [props.initialValue]
 * @param {boolean} [props.required]
 *
 */

function FormInput(props) {
  const {
    label,
    getFieldDecorator,
    decoratorKey,
    required,
    initialValue,
    message,
  } = props;

  return (
    <Form.Item label={label}>
      {getFieldDecorator(decoratorKey, {
        initialValue: initialValue,
        rules: [{ required: required, message: message }],
      })(<Input />)}
    </Form.Item>
  );
}
FormInput.defaultProps = {
  initialValue: null,
  required: true,
};

FormInput.propTypes = propTypes;

export default FormInput;
