import './Speciality.Styles.scss';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Icon, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import CreateSpecialityModalComponent from '../../Components/CreateSpecialityModal/CreateSpecialityModal.Component';
import DeleteModal from '../../Components/DeleteModal/DeleteModal.Component';
import EditSpecialityModalComponent from '../../Components/EditSpecialityModal/EditSpecialityModal.Component';
import { LoadingHandler } from '../../Reducers/Loading.Reducer';
import { AlertService } from '../../Services/Alert.Service';
import { SpecialityService } from '../../Services/Speciality.Service';
import { ValidationService } from '../../Services/Validation.Service';
import { columns } from './Speciality.Column';

class SpecialityContainer extends Component {
  state = {
    //modals
    createModal: false,
    deleteModal: false,
    editModal: false,
    //modals data
    editSpeciality: {},
    deleteSpeciality: {},
    //container data
    specialities: [],
    //pagination
    textFilter: null,
    countSpecialities: null,
  };

  componentDidMount = () => {
    this.props.setLoading(true);
    this.init();
  };

  init = () => {
    this.getSpecialities();
  };

  getSpecialities = async () => {
    const { textFilter } = this.state;

    const { data, count } = await SpecialityService.list({ textFilter });
    this.props.setLoading(false);

    return this.setState({ specialities: data, countSpecialities: count });
  };

  createSpeciality = async () => {
    const { specialities } = this.state;
    const { form } = this.props;
    const values = await ValidationService.validateForm(form);

    try {
      this.closeCreateModal();
      const newSpeciality = await SpecialityService.create(values);
      form.resetFields();
      this.setState({
        specialities: [newSpeciality, ...specialities],
        countSpecialities: this.state.countSpecialities + 1,
      });
    } catch (e) {
      AlertService.error(e);
    }
  };

  editSpeciality = async () => {
    let { specialities, editSpeciality } = this.state;
    const { form } = this.props;
    const values = await ValidationService.validateForm(form);

    try {
      const index = specialities.findIndex(
        (item) => item.id === editSpeciality.id
      );
      const editedSpeciality = { ...editSpeciality, ...values };
      specialities[index] = editedSpeciality;
      form.resetFields();
      this.setState({ specialities }, this.closeEdit);
      await SpecialityService.edit(editedSpeciality);
    } catch (e) {
      AlertService.error(e);
    }
  };

  deleteSpeciality = async (id) => {
    try {
      await SpecialityService.delete(id);
      this.getSpecialities();
      this.setState({ deleteModal: false });
    } catch (e) {
      AlertService.error(e);
    }
  };

  onSearch = (textFilter) => {
    const isEmpty = ValidationService.isEmpty(textFilter);
    return this.setState(
      { textFilter: isEmpty ? null : textFilter },
      this.getSpecialities
    );
  };

  closeSearch = () => {
    this.setState({ textFilter: null }, this.getSpecialities);
  };

  //modal functions

  openEdit = (editSpeciality) =>
    this.setState({ editModal: true, editSpeciality });

  openDelete = (deleteSpeciality) =>
    this.setState({ deleteModal: true, deleteSpeciality });

  closeEdit = () => this.setState({ editModal: false, editSpeciality: {} });
  closeDelete = () => this.setState({ deleteModal: false });

  openCreateModal = () => this.setState({ createModal: true });

  closeCreateModal = () => {
    const { form } = this.props;
    form.resetFields();
    this.setState({ createModal: false });
  };

  render() {
    const {
      specialities,
      createModal,
      editModal,
      editSpeciality,
      deleteSpeciality,
      textFilter,
      deleteModal,
    } = this.state;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const cols = [
      ...columns,
      {
        title: 'Acțiuni',
        key: 'action',
        render: (item) => (
          <div>
            <span>
              <Button onClick={() => this.openEdit(item)}>Editează</Button>
            </span>
            <Divider type="vertical" />
            <span>
              <Button onClick={() => this.openDelete(item)}>Șterge</Button>
            </span>
          </div>
        ),
      },
    ];

    return (
      <div className="specialities__content specialities__content--padding">
        <div className="options">
          <div className="options__search">
            {textFilter && (
              <Button onClick={this.closeSearch}>
                <CloseCircleOutlined className="options__search__btn--cancel" />
              </Button>
            )}
            <Search
              placeholder="Caută Specialitate"
              onSearch={this.onSearch}
              className="options__search__input"
            />
          </div>
          <div className="options__container-icon">
            <div className="options__container-icon__icon">
              <Icon type="plus-circle" onClick={this.openCreateModal} />
            </div>
            <span className="options__container-icon__iconPopup-speciality">
              Adaugă Specialitate
            </span>
          </div>
        </div>
        <Table
          columns={cols}
          dataSource={specialities}
          className="table"
          rowKey="id"
        />

        <CreateSpecialityModalComponent
          visible={createModal}
          onOk={this.createSpeciality}
          onCancel={this.closeCreateModal}
          getFieldDecorator={getFieldDecorator}
        />

        <EditSpecialityModalComponent
          visible={editModal}
          onOk={this.editSpeciality}
          onCancel={this.closeEdit}
          getFieldDecorator={getFieldDecorator}
          speciality={editSpeciality}
        />
        <DeleteModal
          title="Șterge Specialitate"
          item={deleteSpeciality}
          visible={deleteModal}
          text="Șterge Specialitatea?"
          onOk={this.deleteSpeciality}
          onCancel={this.closeDelete}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setLoading: (loading) => dispatch(LoadingHandler.setLoading(loading)),
});

const WrappedSpecialityContainer = Form.create({
  name: 'SpecialityContainerForm',
})(SpecialityContainer);

export default connect(null, mapDispatchToProps)(WrappedSpecialityContainer);
