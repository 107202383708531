import { Form, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import FormInput from '../FormItems/FormInput.Component';
import FormSelect from '../FormItems/FormSelect.Component';

const propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialValue: PropTypes.object.isRequired,
  getFieldDecorator: PropTypes.func.isRequired,
};

/**
 *
 * @param {object} props
 * @param {string} props.title
 * @param {boolean} props.visible
 * @param {any} props.onOk
 * @param {any} props.onCancel
 * @param {any} props.getFieldDecorator
 * @param {object} props.initialValue
 *
 */

function EditUserModal(props) {
  const {
    title,
    visible,
    onOk,
    onCancel,
    getFieldDecorator,
    initialValue,
  } = props;

  const [role, setRole] = useState(null);
  function onChangeRole(value) {
    setRole(value);
  }
  const roles = [
    {
      name: 'Admin',
      id: 1,
    },
    {
      name: 'Șef grupă',
      id: 2,
    },
  ];
  return (
    <Modal title={title} visible={visible} onOk={onOk} onCancel={onCancel}>
      <Form labelCol={{ span: 5 }} wrapperCol={{ span: 12 }}>
        <FormInput
          label="Prenume"
          getFieldDecorator={getFieldDecorator}
          decoratorKey="firstName"
          initialValue={initialValue.firstName}
          message="Introdu Prenumele"
        />

        <FormInput
          label="Nume"
          getFieldDecorator={getFieldDecorator}
          decoratorKey="lastName"
          initialValue={initialValue.lastName}
          message="Introdu Numele"
        />

        <FormInput
          label="Email"
          getFieldDecorator={getFieldDecorator}
          decoratorKey="email"
          initialValue={initialValue.email}
          message="Introdu email-ul"
        />

        <FormInput
          label="Parolă"
          getFieldDecorator={getFieldDecorator}
          decoratorKey="password"
          message="Introdu parola"
          required={false}
        />

        <FormSelect
          label="Rol"
          getFieldDecorator={getFieldDecorator}
          decoratorKey="roleId"
          initialValue={initialValue.roleId}
          selectValues={roles}
          message="Introdu rol-ul"
          onChangeRole={onChangeRole}
        />

        {initialValue.roleId !== 2 && role !== 2 && (
          <FormInput
            label="Funcție"
            decoratorKey="position"
            message="Introdu funcția"
            getFieldDecorator={getFieldDecorator}
            initialValue={initialValue.position}
          />
        )}
      </Form>
    </Modal>
  );
}

EditUserModal.propTypes = propTypes;

export default EditUserModal;
