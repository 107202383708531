import './Typography.scss';

import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  text: PropTypes.string.isRequired,
};

/**
 * Button Component
 * @param {object} props
 * @param {string} props.text
 *
 */
function Typography(props) {
  const { text } = props;
  return <div className="button-link">{text}</div>;
}

Typography.propTypes = propTypes;

export default Typography;
