import './Login.Styles.scss';
import 'antd/dist/antd.css';

import { Button, Form, Icon, Input } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { AppRoutes } from '../../Constants/AppRoutes.constant';
import { ROLES } from '../../Constants/Roles.constant';
import { AuthHandler } from '../../Reducers/Auth.Reducer';
import { LoadingHandler } from '../../Reducers/Loading.Reducer';
import { UserHandler } from '../../Reducers/User.Reducer';
import { AlertService } from '../../Services/Alert.Service';
import { ApiService } from '../../Services/Api.Service';
import { AuthService } from '../../Services/Auth.Service';
import { UserService } from '../../Services/User.Service';
import { ValidationService } from '../../Services/Validation.Service';

class LoginContainer extends Component {

  componentDidMount = () => this.props.setLoading(false);

  logIn = async () => {
    const { form } = this.props;
    try {
      const values = await ValidationService.validateForm(form);
      const auth = await AuthService.login(values);
      this.setUserAuth(auth);
      this.setUserData();
    } catch (e) {
      AlertService.error(e);
    }
  };

  setUserAuth = (auth) => {
    ApiService.setAuth(auth);
    return this.props.setAuth(auth);
  };

  setUserData = async () => {
    const user = await UserService.me();
    const isAdmin = this.checkRole(user);
    if (isAdmin) {
      await this.props.setUser(user);
      return this.props.history.push(AppRoutes.groups);
    }
    return this.props.setAuth(null);
  };

  checkRole = (user) =>
    user.role.id === ROLES.ADMIN || user.role.id === ROLES.LEADER;

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form className="login-form">
        <Form.Item>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            onClick={this.logIn}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setAuth: (auth) => dispatch(AuthHandler.setAuth(auth)),
  setUser: (user) => dispatch(UserHandler.setUser(user)),
  setLoading: (loading) => dispatch(LoadingHandler.setLoading(loading)),
});

const reduxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);

const login = withRouter(reduxContainer);

export default Form.create({ name: 'LoginContainer' })(login);
